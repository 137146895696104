import React from 'react'
import css from './Menu.module.scss'

function MenuItem(props) {
    return (
        <div
            className={css.MenuItem}
            onClick={props.onClick ? props.onClick : null}
        >
            {props.iconSvg ?
                <div className={css.MenuItemContainer}>
                    <div className={css.IconContainer}>
                        { props.iconSvg || '' }
                    </div>
                    <div className={css.IconText}>
                        {props.bold ? <strong>{props.text}</strong> : props.text}
                    </div>
                </div>
            : props.text }
            {props.children}
        </div>
    )
}

export default MenuItem
