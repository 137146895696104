import React from 'react'
import css from './TextInput.module.scss'
import {useState} from 'react'

function TextInput(props) {
    const [internalText, setInternalText] = useState('')
    const text = props.text || internalText
    const setText = props.setText || setInternalText
    const focusOnSelect = props.focusOnSelect || false
    const onClick = () => {}
    const onKeyDown = () => {}
    const onKeyPress = props.onlyNumbers ? (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    } : () => {}

    return (
        <input
            type={props.password ? 'password' : 'text'}
            data-testid="text-input"
            className={`${css.TextInput} ${props.css}`}
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder={props.placeholder || ''}
            ref={props.ref}
            autoFocus={props.autoFocus}
            onClick={props.onClick || onClick}
            disabled={props.disabled}
            onKeyDown={props.onKeyDown || onKeyDown}
            pattern={props.pattern}
            onFocus={focusOnSelect ? (e) => { e.target.select() } : () => {}}
            onKeyPress={onKeyPress}
        />
    )
}

export default TextInput
