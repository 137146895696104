import css from './AboutWin.module.scss'
import Win from '../../../library/win/Win'
import svg from './thetool.svg'
import React from 'react'
import pack from "../../../../../package.json"

function AboutWin({ keyProp }) {
    return (
        <Win
            title="About"
            className={css.Body}
            keyProp={keyProp}
            x={250}
            y={250}
            h={280}
        >
            <img className={css.Img} src={svg} alt="gondo"/>
            <div className={css.Title}>
                gondo
            </div>
            <div className={css.Version}>
                <div className={css.TextContainer}>
                    {pack.version}
                </div>
            </div>
        </Win>
    );
}

export default React.memo(AboutWin);
