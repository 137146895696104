import baseApi from './BaseApi';

const fetchPrompts = (dispatch) => baseApi(dispatch).get("/prompts")
const fetchPrompt = (dispatch, id) => baseApi(dispatch).get("/prompts/" + id)

const fetchPromptTemplate = (dispatch) => baseApi(dispatch).get("/prompt_template")
const createPrompt = (dispatch, data) => baseApi(dispatch).post("/prompts", data)
const updatePrompt = (dispatch, id, data) => baseApi(dispatch).put("/prompts/" + id, data)
const deletePrompt = (dispatch, id) => baseApi(dispatch).delete("/prompts/" + id)
const executePrompt = (dispatch, id, data) => baseApi(dispatch).post("/prompts/" + id + "/exec", data, { withCredentials: true })


export {
    fetchPrompts,
    fetchPrompt,
    createPrompt,
    updatePrompt,
    deletePrompt,
    executePrompt,
    fetchPromptTemplate,
}
