import React from "react";
import { Editor } from "@monaco-editor/react";

function CodeEditor(props){
    function handleValue(e){
        props.setValue(e);
    }

    return (
        <Editor
            language={props.language}
            className={props.className}
            defaultValue={props.defaultValue}
            value={props.value}
            theme={props.theme}
            options={props.options}
            onChange={handleValue}
        />
    )
}

export default CodeEditor;