import React from 'react'
//import css from './Spinner.module.scss'
import {SyncLoader} from "react-spinners";

function Spinner(props) {
    return (
        <>
            <SyncLoader
                loading={true}
                size={props.size || 6}
                color="rgba(235, 235, 245, 0.6)"
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </>
    );
}

export default Spinner;
