import React from 'react'
import css from './Table.module.scss'

function Table(props) {
    const headers = props.headers
    const data = props.data

    return (
        <div
            className={`${css.MainComponent} ${props.css}`}
        >
            <div className={css.TableContainer}>
                <table className={css.Table}>
                    <thead className={css.THead}>
                    <tr>
                        { headers.map((header, index) =>
                            <th key={`head-${index}`} className={css.HeaderColumn} style={header.style}>
                                {header.name}
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    { data.map((record, index) =>
                        <tr className={css.TR} key={`tr-${index}`}>
                            { headers.map((header, index) =>
                                <td className={css.TD} key={`td-${index}`}>
                                    {record[header.name]}
                                </td>
                            ) }
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Table;
