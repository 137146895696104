import css from './ExplorerWin.module.scss'
import Win from '../../../library/win/Win'
import React, {useEffect, useState} from 'react'
import Button from '../../../library/button/Button'
import { AiFillDelete } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import Table from "../../../library/table/Table";
import ActionButton from "../../../library/action_button/ActionButton";

function ExplorerWin(props) {
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const itemsPerPage = props.itemsPerPage || 15

    const data = props.data
    const getData = props.getData
    const deleteRecord = props.deleteRecord
    const editRecord = props.editRecord
    const headers = props.headers
    const nextDisable = page >= maxPage
    const prevDisable = page <= 1
    const addData = props.addData

    useEffect(() => {
        getData && getData()
    }, [])

    useEffect(() => {
        if (props.data == null) { return }

        setMaxPage(props.data.length / itemsPerPage)
        setPage(1)
    }, [props.data]);

    function currentData() {
        if (props.data == null) { return [] }

        let amount = props.data.length / itemsPerPage
        //let rest = props.data.length % itemsPerPage

        return data.slice((page - 1) * amount, ((page - 1) * amount) + itemsPerPage)
    }

    function nextPage() {
        if (page < maxPage) {
            setPage(page => page + 1)
        }
    }

    function prevPage() {
        if (page > 1) {
            setPage(page => page - 1)
        }
    }

    return (
        <Win
            title={props.title || 'Explorer'}
            keyProp={props.keyProp}
            x={300}
            y={100}
            w={700}
            h={500}
            winName={props.winName}
            modal={props.modal}
        >
            <div className={css.Body}>
                <div className={css.OptionsContainer}>
                    <Button
                        onClick={() => {
                            getData && getData()
                        }}
                    >
                        Refresh
                    </Button>
                    <Button
                        onClick={() => {
                            if (addData) {
                                addData()
                            }
                        }}
                    >
                        Add
                    </Button>
                    <Button
                        disabled={nextDisable}
                        onClick={() => {
                            nextPage()
                        }}
                    >
                        Next
                    </Button>
                    <Button
                        disabled={prevDisable}
                        onClick={() => {
                            prevPage()
                        }}
                    >
                        Prev
                    </Button>
                </div>
                <div className={css.Container}>
                    <Table
                        headers={headers}
                        data={currentData().map(record => {
                            return {...record, Actions:
                            <>
                                {props.customActions && props.customActions.map(action =>
                                <ActionButton
                                    icon={action.icon}
                                    key={`action-button-${action.label}`}
                                    onClick={() => action.callback(record[action.field])}
                                />)}
                                <ActionButton
                                    icon={<BiEdit/>}
                                    onClick={() => {
                                        editRecord(record.ID)
                                    }}
                                />
                                <ActionButton
                                    icon={<AiFillDelete/>}
                                    onClick={() => {
                                        deleteRecord(record.ID)
                                    }}
                                />

                            </>}
                        })}
                    />
                </div>
            </div>
        </Win>
    );
}

export default ExplorerWin;
