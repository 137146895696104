import css from '../UtilitaryWins.module.scss'
import Win from '../../../../../library/win/Win'
import React, {useState, useContext, useEffect} from 'react'
import TextInput from '../../../../../library/textinput/TextInput'
import Button from '../../../../../library/button/Button'
import {Context} from '../../../../../../data/Context'
import InputLabel from "../../../../../library/input_label/InputLabel";
import {createPrompt, fetchPrompt, fetchPromptTemplate, updatePrompt} from "../../../../../../services/api/Prompt";
import TextArea from "../../../../../library/textarea/TextArea";
import {fetchModels} from "../../../../../../services/api/Model";
import Select from "../../../../../library/select/Select";

function NewPromptWin(props) {
    const [prompt, setPrompt] = useState({})
    const [models, setModels] = useState([])
    const {state, dispatch} = useContext(Context);

    useEffect(() => {
        loadModels().then(() => {
            if (props.promptId && props.promptId !== 0)
                load()
            else
                loadTemplate()
        })
    }, [props.promptId])

    function load() {
        fetchPrompt(dispatch, props.promptId).then(response => {
            setPrompt(response.data)
        })
    }

    function loadTemplate() {
        fetchPromptTemplate(dispatch).then(response => {
            setPrompt(response.data)
        })
    }

    function loadModels() {
        return fetchModels(dispatch).then(response => {
            setModels(response.data.models)
        })
    }

    function title() {
        return props.promptId && props.promptId !== 0 ? 'Edit Prompt' : 'New Prompt'
    }

    function setModel(id) {
        setPrompt(prompt => {
            return {...prompt, AiModelID: parseInt(id)}
        })
    }

    function savePrompt() {
        if (props.promptId) {
            updatePrompt(dispatch, props.promptId, prompt).then(() => {
                props.getData()
                state.managerFunctions.closeWin(state.activeWin)
            })

            state.managerFunctions.closeWin(state.activeWin)
            return;
        }

        createPrompt(dispatch, prompt).then(() => {
            props.getData()
            state.managerFunctions.closeWin(state.activeWin)
        })
    }

    return (
        <Win
            title={title()}
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={465}
            w={450}
        >
            <div className={css.Body}>
                <InputLabel label="Name">
                    <TextInput
                        css={css.Input}
                        text={prompt.Name}
                        setText={text => setPrompt(prompt => {
                            return {...prompt, Name: text}
                        })}
                    />
                </InputLabel>
                <InputLabel label="Model">
                    <Select
                        option={prompt.AiModel}
                        setSelectedId={setModel}
                        options={models}
                        selectedId={prompt.AiModelID}
                        optionKey="ID"
                    />
                </InputLabel>
                <InputLabel label="Max Tokens">
                    <TextInput
                        css={css.Input}
                        text={prompt.MaxTokens}
                        setText={text => setPrompt(prompt => {
                            return {...prompt, MaxTokens: parseInt(text)}
                        })}
                        onlyNumbers={true}
                    />
                </InputLabel>
                <InputLabel label="System Rules">
                    <TextArea
                        rows={7}
                        css={css.Input}
                        text={prompt.System}
                        setText={text => setPrompt(prompt => {
                            return {...prompt, System: text}
                        })}
                    />
                </InputLabel>
                <InputLabel label="Default User Input">
                    <TextArea
                        rows={7}
                        css={css.Input}
                        text={prompt.Input}
                        setText={text => setPrompt(prompt => {
                            return {...prompt, Input: text}
                        })}
                    />
                </InputLabel>
                <div className={css.ButtonBar}>
                    <Button
                        onClick={() => {
                            savePrompt()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default NewPromptWin;
