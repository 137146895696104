import ExplorerWin from "../../ExplorerWin";
import React, {useState, useContext} from "react";
import {deleteChain, fetchChains} from "../../../../../../services/api/Chain";
import { Context } from "../../../../../../data/Context";

function ChainsWin(props) {
    const {dispatch} = useContext(Context)
    const [chains, setChains] = useState([])
    function getData() {
        fetchChains(dispatch).then(response => {
            setChains(response.data.chains)
        })
    }

    function deleteRecord(id) {
        deleteChain(dispatch, id).then(() => {
            getData()
        })
    }

    return <ExplorerWin
        {...props}
        data={chains}
        getData={getData}
        deleteRecord={deleteRecord}
        title="Chains"
        headers={[
            { name: 'ID', style: { width: '10%' } },
            { name: 'Name', style: { width: '80%' } },
            { name: 'Actions', style: { width: '10%' } },
        ]}
    />
}

export default ChainsWin
