import baseApi from './BaseApi';

const fetchModels = (dispatch) => baseApi(dispatch).get("/models")
const fetchModel = (dispatch, id) => baseApi(dispatch).get("/models/" + id)
const createModel = (dispatch, data) => baseApi(dispatch).post("/models", data)
const updateModel = (dispatch, id, data) => baseApi(dispatch).put("/models/" + id, data)
const deleteModel = (dispatch, id) => baseApi(dispatch).delete("/models/" + id)

const executeModel = (dispatch, id, data) => baseApi(dispatch).post("/models/" + id + "/exec", data)

export {
    fetchModels,
    fetchModel,
    createModel,
    updateModel,
    deleteModel,
    executeModel,
}
