import css from './Win.module.scss'
import {useContext, useEffect, useRef, useState} from 'react'
import {Context} from '../../../data/Context'
import React from 'react'

function Win(props) {
    const { state, dispatch } = useContext(Context)
    const [active, setActive] = useState(true)
    const hasButtons = props.hasButtons || true

    let winRef = useRef()
    if (props.winRef) {
        winRef = props.winRef
    }

    const sensitive = true //winRef && state.activeWin && state.activeWin === winRef.current

    let x = props.x
    let y = props.y
    let w = props.w || 300
    let h = props.h || 300

    if (props.center) {
        let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        x = (screenWidth / 2) - (w / 2);
        y = (screenHeight / 2) - (h / 2);
    }

    useEffect(() => {
        dispatch({ type: 'SET_ACTIVE_WIN', activeWin: winRef.current })
    }, [])

    useEffect(() => {
        setActive(state.activeWin === winRef.current)
    }, [state.activeWin])

    return (
        <div
            className={`${css.Win} ${active && css.ActiveWin}`}
            style={{ top: y, left: x, width: w, height: h }}
            ref={winRef}
            keyprop={props.keyProp}
            data-testid="win"
            data-is-modal={`${props.modal}`}
            data-win-name={props.winName || 'Generic Window'}
            onMouseDown={() => {
                dispatch({ type: 'SET_ACTIVE_WIN', activeWin: winRef.current })
            }}
        >
            {hasButtons && <div
                className={css.CloseIcon}
                onClick={() => {
                    dispatch({ type: 'SET_ACTIVE_WIN', activeWin: null })
                    state.managerFunctions.closeWin(winRef.current)
                }}
            />}
            <div
                className={css.Header}
                onMouseDown={event => {
                    const win = winRef.current
                    const offsetX = event.clientX - parseInt(getComputedStyle(win).left)
                    const offsetY = event.clientY - parseInt(getComputedStyle(win).top)

                    dispatch({ type: 'SET_GRAB_ITEM', grabItem: win })
                    dispatch({ type: 'SET_ACTIVE_WIN', activeWin: win})
                    dispatch({ type: 'SET_WIN_OFFSET', winOffsetX: offsetX, winOffsetY: offsetY })
                }}
            >
                {props.title}
            </div>
            <div style={{pointerEvents: sensitive ? 'auto' : 'none'  }} className={`${css.Body} ${props.className}`}>
                {props.children}
            </div>
        </div>
    );
}

//export default Win;
export default React.memo(Win);
