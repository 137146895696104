import React from 'react'
import css from './Menu.module.scss'

function SubMenu(props) {
    return (
        <div className={css.SubMenu}>
            {props.children}
        </div>
    )
}

export default SubMenu
