import axios from 'axios'
import {SERVER} from "./Const";
import baseApi from './BaseApi';

// const fetchUsers = () => axios.get(SERVER + "/users", { withCredentials: true })
const fetchUsers = (dispatch) => baseApi(dispatch).get("/users")
const fetchUser = (id) => axios.get(SERVER + "/users/" + id)
const createUser = (data) => axios.post(SERVER + "/users", data)
const updateUser = (id, data) => axios.put(SERVER + "/users/" + id, data)
const deleteUser = (id) => axios.delete(SERVER + "/users/" + id, { withCredentials: true })


export {
    fetchUsers,
    fetchUser,
    createUser,
    updateUser,
    deleteUser,
}
