import React from "react";
import css from './DevTools.module.scss'

function DevTools() {


    return <div className={css.DevTools}>

    </div>
}

export default DevTools
