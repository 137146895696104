import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../data/Context'
import WinMap from './WinMap'
import {v4 as uuid} from "uuid";
import Cookies from 'js-cookie'
import Flash from '../../application/win/Flash/Flash'
import DevTools from "../dev_tools/DevTools";

function Manager() {
    const [winList, setWinList] = useState([])
    const {state, dispatch} = useContext(Context)

    useEffect(() => {
        dispatch({
            type: "SET_MANAGER_FUNCTIONS",
            managerFunctions: {
                openWin,
                closeWin,
                focusWin,
            }
        })

        const auth = Cookies.get('Authorization')

        if (auth) {
            dispatch({ type: 'SET_LOGGED_IN', loggedIn: true })
            return
        }

        if (!state.loggedIn && winList.length === 0) {
            openWin('LoginWin')
        }
    }, [])

    useEffect(() => {
        document.onmousemove = event => {
            if (!state.grabItem) { return }
            updateElementPosition(state.grabItem, event.clientX, event.clientY)
        }

        document.onmouseup = () => {
            if (!state.grabItem) { return }
            dispatch({ type: 'SET_GRAB_ITEM', grabItem: null })
        }
    }, [state.grabItem])

    useEffect(() => {
        if (state.activeWin) {
            focusWin(state.activeWin)
        } else {
            if (winList[0])
                dispatch({ type: 'SET_ACTIVE_WIN', activeWin: winList[0].current })
        }
    }, [state.activeWin])

    const openWin = (componentName, props = {}, modal = false) => {
        setWinList(winList => {
            const WinTag = WinMap[componentName]

            //const existingModal = winList.find(win => win.props.modal)
            //if (existingModal) return winList

            const key = uuid()
            const winProps = { key, keyProp: key, ...props }
            const win = <WinTag { ...winProps } modal={modal} />

            return [...winList, win]
        })
    }

    const closeWin = winHtml => {
        const winKey = winHtml.attributes.keyprop.value

        setWinList(winList => {
            const index = winList.findIndex(win => win.key === winKey)
            if (index === -1) { return winList }

            const newWinList = [...winList]
            newWinList.splice(index, 1)

            return newWinList
        })
    }

    const focusWin = winHtml => {
        const winKey = winHtml.attributes.keyprop.value

        if (winList.find(win => win.modal)) {
            return
        }

        setWinList(winList => {
            const index = winList.findIndex(win => win.key === winKey)
            if (index === -1) { return winList }

            const itemToMove = winList[index]
            const newWinList = [...winList]
            newWinList.splice(index, 1)
            newWinList.push(itemToMove)

            return newWinList
        })
    }

    function updateElementPosition(element, mouseX, mouseY) {
        const elementRect = element.getBoundingClientRect()
        const maxX = window.innerWidth - elementRect.width
        const maxY = window.innerHeight - elementRect.height

        let newX = mouseX - state.winOffsetX
        let newY = mouseY - state.winOffsetY

        newX = Math.max(0, Math.min(newX, maxX))
        newY = Math.max(0, Math.min(newY, maxY))

        element.style.left = `${newX}px`
        element.style.top = `${newY}px`
        element.style.right = 'unset'
        element.style.bottom = 'unset'
    }

    return (
        <>
            {winList}
            <Flash/>
            <DevTools/>
        </>
    );
}

export default Manager
