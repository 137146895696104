import ExplorerWin from "../../ExplorerWin";
import React, {useState, useContext} from "react";
import {deletePrompt, fetchPrompts} from "../../../../../../services/api/Prompt";
import { Context } from "../../../../../../data/Context";
import {TbPrompt} from "react-icons/tb";

function PromptsWin(props) {
    const {state, dispatch} = useContext(Context)

    const [prompts, setPrompts] = useState([])
    function getData() {
        return fetchPrompts(dispatch).then(response => {
            setPrompts(response.data.prompts)
        })
    }

    function deleteRecord(id) {
        return deletePrompt(dispatch, id).then(() => {
            getData()
        })
    }

    return <ExplorerWin
        {...props}
        data={prompts}
        getData={getData}
        editRecord={(promptId) => state.managerFunctions.openWin('NewPromptWin', { promptId: promptId, getData })}
        addData={() => state.managerFunctions.openWin('NewPromptWin', { promptId: 0, getData })}
        deleteRecord={deleteRecord}
        title="Prompts"
        customActions={[
            {
                label: 'Execute',
                field: 'ID',
                icon: <TbPrompt/>,
                callback: (id) => {
                    state.managerFunctions.openWin('PromptExecWin', { promptId: id, getData })
                }
            }
        ]}
        headers={[
            { name: 'ID', style: { width: '10%' } },
            { name: 'Name', style: { width: '70%' } },
            { name: 'Actions', style: { width: '10%' } },
        ]}
    />
}

export default PromptsWin
