import baseApi from './BaseApi';

const fetchUnitTests = (dispatch) => baseApi(dispatch).get("/tests")
const fetchUnitTest = (dispatch, id) => baseApi(dispatch).get("/tests/" + id)
const createUnitTest = (dispatch, data) => baseApi(dispatch).post("/tests", data)
const updateUnitTest = (dispatch, id, data) => baseApi(dispatch).put("/tests/" + id, data)
const deleteUnitTest = (dispatch, id) => baseApi(dispatch).delete("/tests/" + id)

const executeUnitTest = (dispatch, id, data) => baseApi(dispatch).post("/tests/" + id + "/exec", data)

export {
    fetchUnitTests,
    fetchUnitTest,
    createUnitTest,
    updateUnitTest,
    deleteUnitTest,
    executeUnitTest,
}
