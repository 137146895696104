import React from 'react'
import css from './PropertiesEditor.module.scss'
//import uuid from "react-uuid";

function PropertiesEditor(props) {
    return (
            <table className={`${css.PropertiesEditor} ${props.className}`} data-testid="properties-editor">
                <thead>
                <tr className={css.Headers}>
                    <th>{ props.propertyLabel || 'Property'}</th>
                    <th>{ props.valueLabel || 'Value'}</th>
                    { props.hasActions &&
                    <th>&nbsp;</th>}
                </tr>
                </thead>
                <tbody>
                { props.properties.map(property => {
                    return (<tr className={css.Property} key={`__prop_${property.Name}`}>
                        <td className={css.Cell}>{property.Name}</td>
                        <td className={css.Cell}>{property.Value}</td>
                        { props.hasActions &&
                        <td className={css.Cell}>{property.actions}</td> }
                    </tr>)
                })}
                </tbody>
            </table>
    )
}

export default PropertiesEditor
