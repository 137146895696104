import ExplorerWin from "../../ExplorerWin";
import React, {useState, useContext} from "react";
import {deleteModel, fetchModels} from "../../../../../../services/api/Model";
import { Context } from "../../../../../../data/Context";
import {TbPrompt} from "react-icons/tb";

function ModelsWin(props) {
    const { state, dispatch } = useContext(Context)

    const [models, setModels] = useState([])

    function getData() {
        fetchModels(dispatch).then(response => {
            setModels(response.data.models)
        })
    }

    function deleteRecord(id) {
        deleteModel(dispatch, id).then(() => {
            getData()
        })
    }


    return <ExplorerWin
        {...props}
        data={models}
        getData={getData}
        deleteRecord={deleteRecord}
        addData={() => state.managerFunctions.openWin('NewModelWin', { getData })}
        title="Models"
        editRecord={(modelId) => state.managerFunctions.openWin('NewModelWin', { getData, modelId })}
        customActions={[
            {
                label: 'Execute',
                field: 'ID',
                icon: <TbPrompt/>,
                callback: (id) => {
                    state.managerFunctions.openWin('ModelExecWin', { modelId: id })
                }
            }
        ]}
        headers={[
            { name: 'ID', style: { width: '10%' } },
            { name: 'Name', style: { width: '30%' } },
            { name: 'Description', style: { width: '50%' } },
            { name: 'Actions', style: { width: '10%' } },
        ]}
    />
}

export default ModelsWin
