import React from 'react'
import css from './InputLabel.module.scss'

function InputLabel(props) {
    return (
        <div
            className={`${css.InputLabel} ${props.css}`}
            onClick={props.disabled ? (() => {}) : props.onClick}
        >
            <div className={css.Label}>
                {props.label}
            </div>
            <div className={`${css.Body} ${props.bodyCss}`}>
                {props.children}
            </div>
        </div>
    );
}

export default InputLabel;
