import baseApi from './BaseApi';

const fetchImage = (dispatch, id) => baseApi(dispatch).get("/images/" + id)
const createImage = (dispatch, data) => baseApi(dispatch).post("/images", data)
const updateImage = (dispatch, id, data) => baseApi(dispatch).put("/images/" + id, data)

export {
    fetchImage,
    createImage,
    updateImage,
}
