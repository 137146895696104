import css from '../UtilitaryWins.module.scss'
import customCss from './NewTestWin.module.scss'
import Win from '../../../../../library/win/Win'
import React, {useState, useContext, useEffect, useRef} from 'react'
import TextInput from '../../../../../library/textinput/TextInput'
import Button from '../../../../../library/button/Button'
import {Context} from '../../../../../../data/Context'
import { createUnitTest, fetchUnitTest, updateUnitTest } from '../../../../../../services/api/UnitTest'
import { fetchPrompts } from '../../../../../../services/api/Prompt'
import { fetchChains } from '../../../../../../services/api/Chain'
import InputLabel from "../../../../../library/input_label/InputLabel";
import Select from '../../../../../library/select/Select'
import CodeEditor from '../../../../../library/code_editor/CodeEditor'

function NewTestWin(props) {
    const [name, setName] = useState('');
    const [rawInput, setRawInput] = useState("");
    const [assertion, setAssertion] = useState("function main(input, result, expected){}");
    const {state, dispatch} = useContext(Context);
    const [selectedActionType, setSelectedActionType] = useState('chain');
    const [actions, setActions] = useState({});
    const [selectedAction, setSelectedAction] = useState({});
    const fileInputRef = useRef(null);

    useEffect(() => {
        loadActions();
        if (props.unitTestId && props.unitTestId !== 0)
            load();
    }, [props.unitTestId])

    function load() {
        fetchUnitTest(dispatch, props.unitTestId).then(response => {
            setName(response.data.Name)
            setRawInput(response.data.Input)
            setAssertion(response.data.Assertions)
            setSelectedActionType(response.data.ActionType)
            setSelectedAction(response.data.ActionID)
        })
    }

    function loadActions() {
        Promise.all([
            fetchPrompts(dispatch), 
            fetchChains(dispatch)
        ]).then(response => 
            setActions({
                Prompt: response[0].data.prompts.map(prompt => {
                  return {
                    ID: prompt.ID,
                    Name: prompt.Name,
                  }
                }),
                Chain: response[1].data.chains.map(chain => {
                  return {
                    ID: chain.ID,
                    Name: chain.name
                  }
                })
            })
        )
    }

    function title() {
        return props.unitTestId && props.unitTestId !== 0 ? 'Edit Test' : 'New Test'
    }

    function fileInputChange(e){
        const file = e.target.files[0];
        e.target.value = '';

        if (file){
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");

            reader.onload = function (evt) {
                console.log(evt.target.result);
                setRawInput(evt.target.result);
                console.log(evt);
            }

            reader.onerror = function(evt){
                console.log(evt);
            }
        }
    }

    function saveTest() {
        if (props.unitTestId) {
            updateUnitTest(dispatch, props.unitTestId, {
              Name: name,
              RawInput: rawInput,
              Assertions: assertion,
              ActionType: selectedActionType.charAt(0).toUpperCase() + selectedActionType.slice(1),
              ActionID: parseInt(selectedAction),
            }).then(() => {
                props.getData()
                state.managerFunctions.closeWin(state.activeWin)
            })

            return;
        }

        createUnitTest(dispatch, {
            Name: name,
            RawInput: rawInput,
            Assertions: assertion,
            ActionType: selectedActionType.charAt(0).toUpperCase() + selectedActionType.slice(1),
            ActionID: parseInt(selectedAction),
        }).then(() => {
            props.getData()
            state.managerFunctions.closeWin(state.activeWin)
        })
    }

    return (
      <Win
        title={title()}
        keyProp={props.keyProp}
        x={300}
        y={190}
        h={760}
        w={500}
      >
        <div className={css.Body}>
          <InputLabel label="Name">
            <TextInput css={css.Input} text={name} setText={setName} />
          </InputLabel>
          <div className={customCss.SelectArea}>
            <InputLabel label="Action Type">
              <Select
                setSelectedId={setSelectedActionType}
                options={[
                  { Name: "Chain" },
                  { Name: "Prompt" },
                ]}
                optionKey="Name"
                selectedId={selectedActionType}
              />
            </InputLabel>
            <InputLabel label="Action Value">
              <Select 
                optionKey="ID" 
                options={actions[selectedActionType]} 
                setSelectedId={setSelectedAction}
                selectedId={selectedAction}
              />
            </InputLabel>
          </div>
          <input
            type="file"
            accept="jsonl"
            style={{ display: "none" }}
            ref={fileInputRef}
            id="fileInput"
            onInput={fileInputChange}
          />
          <Button onClick={() => fileInputRef.current.click()}>Importar</Button>
          <Button onClick={() => setRawInput('')}> Limpar </Button>

          <InputLabel label="JsonL Editor" />
          <CodeEditor
            language="json"
            theme="vs-dark"
            className={customCss.Editor}
            value={rawInput}
            options={{
              minimap: { enabled: false },
              dropIntoEditor: { enabled: true },
            }}
            setValue={setRawInput}
          />
          <InputLabel label="Assertion" />
          <CodeEditor
            language="javascript"
            theme="vs-dark"
            defaultValue={assertion}
            value={assertion}
            className={customCss.Editor}
            setValue={setAssertion}
            options={{
              minimap: { enabled: false },
              dropIntoEditor: { enabled: true },
            }}
          />
          <div className={css.ButtonBar}>
            <Button
              onClick={() => {
                saveTest();
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => state.managerFunctions.closeWin(state.activeWin)}
            >
              Close
            </Button>
          </div>
        </div>
      </Win>
    );
}

export default NewTestWin;
