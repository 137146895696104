import css from './PromptExecWin.module.scss'
import Win from '../../../../../library/win/Win'
import React, {useContext, useEffect, useRef, useState} from "react";
import TextArea from "../../../../../library/textarea/TextArea";
import Button from "../../../../../library/button/Button";
import {Context} from "../../../../../../data/Context";

import CheckBox from "../../../../../library/check_box/CheckBox";
import {
    executePrompt,
    fetchPrompt,
    fetchPrompts,
    updatePrompt
} from "../../../../../../services/api/Prompt";
import {fetchModels} from "../../../../../../services/api/Model";
import TextInput from "../../../../../library/textinput/TextInput";
import Select from "../../../../../library/select/Select";
import PropertiesEditor from "../../../../../library/properties_editor/PropertiesEditor";
import ContextEditor from "../../../../../library/context_editor/ContextEditor";
import {interpolateContext, updateContext} from "../../../../../../services/api/Context";
import uuid from "react-uuid";
import {AiOutlineClear, AiOutlineSave} from "react-icons/ai";
import {ImEmbed} from "react-icons/im";
import {ColorRing} from "react-loader-spinner";
import {BsImage} from "react-icons/bs";

function PromptExecWin(props) {
    const [input, setInput] = useState('')
    const [logs, setLogs] = useState('')
    const [prompt, setPrompt] = useState({})
    const [models, setModels] = useState([])
    const {dispatch} = useContext(Context)
    const [history, setHistory] = useState([])
    const inputRef = useRef(null)
    const bottomRef = useRef(null)
    const logsRef = useRef(null)
    const [disabled, setDisabled] = useState(false)
    const [isWaiting, setIsWaiting] = useState(false)
    const [inputTokens, setInputTokens] = useState(0)
    const [outputTokens, setOutputTokens] = useState(0)
    const [cost, setCost] = useState(0.0)

    useEffect(() => {
        setPromptId(props.promptId)

        fetchPrompts(dispatch).then(() => {
            /*setPrompts(response.data.prompts)*/

            fetchModels(dispatch).then(response => {
                setModels(response.data.models)
            })
        })
    }, [])

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [history])

    useEffect(() => {
        setTimeout(() => {
            if (logsRef.current) {
                logsRef.current.scrollTop = logsRef.current.scrollHeight;
            }
        }, 300)
    }, [logs])

    function pushMessage(role, content, embeddingDistances = null, imageDescriptors = null) {
        setHistory(history => [...history, {
            role, content, embeddingDistances, imageDescriptors
        }])
    }

    function cropResult(s) {
        const limit = 120
        if (s.length > limit)
            return s.slice(0, limit) + "..."

        return s
    }

    function indent(text) {
        return text
            .replaceAll(/\n/g, '<br/>')
    }

    function appendLog(log) {
        const timestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
        setLogs(logs => logs + `[${timestamp}] ` + log + "\n")
    }

    function appendLogs(logs) {
        logs.forEach(log => appendLog(log))
    }

    function downloadLog() {
        const element = document.createElement("a");
        const file = new Blob([logs], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "logs.txt";
        document.body.appendChild(element);
        element.click();
    }

    function setPromptId(id) {
        setDisabled(true)

        fetchPrompt(dispatch, id).then(response => {
            setPrompt(response.data)
            setDisabled(false)
            setInput(response.data.Input)
            inputRef.current.focus()
        })
    }

    function setModelId(id) {
        id = parseInt(id)
        setPrompt(prompt => {
            return {...prompt, AiModelID: id, AiModel: models.find(model => model.ID === id)}
        })
    }

    function setContext(context) {
        setPrompt(prompt => {
            return {
                ...prompt,
                Context: {
                    ...prompt.Context,
                    Data: context,
                }
            }
        })
    }

    function savePrompt() {
        updatePrompt(dispatch, prompt.ID, prompt).then(response => {
            if (response.data.error) {
                appendLog(response.data.error.Message)
            } else {
                updateContext(dispatch, prompt.ContextID, prompt.Context).then(response => {
                    console.log(response)
                })
            }
        })
        appendLog("Updated!")
    }

    const algorithmLabel = {
        0: "Cosine Similarity",
        1: "Inner Product",
        2: "L2 Distance"
    }

    function ask() {
        if (input.length === 0) return

        if (prompt.Context.Data.filter(variable => variable.type === 'embedding' && !variable.embeddingId).length > 0) {
            appendLog("You need to import a file and save the EmbeddingEditor before using it, or remove them from your prompt")
            return
        }

        setInput('')
        inputRef.current.focus()

        setDisabled(true)
        setIsWaiting(true)

        const inputList = prompt.HasMemory ? [...history] : []
        pushMessage('user', input)

        interpolateContext(dispatch, prompt.ContextID, {
            input,
            context: prompt.Context.Data
        }).catch(error => {
            appendLog(error)
            setDisabled(false)
            setIsWaiting(false)
        }).then(response => {
            if (response.data.error) {
                if (response.data.error.Message)
                    appendLog(`${response.data.error.Message} at function '${response.data.error.Location}'`)
                else
                    appendLog(`${response.data.error}`)
                setDisabled(false)
                setIsWaiting(false)
                setInput(prompt.Input)
                return
            }

            setHistory(history => {
                const newHistory = [...history]
                newHistory[newHistory.length - 1].content = response.data.result
                return newHistory
            })

            const requestCode = uuid().slice(0, 8)

            appendLog(`request ${requestCode} started`)

            executePrompt(dispatch, prompt.ID, {
                input,
                system: prompt.System,
                history: inputList,
                maxTokens: prompt.MaxTokens,
                context: prompt.Context.Data,
                aiModelID: parseInt(prompt.AiModelID)
            }).catch(error => {
                appendLog(`request ${requestCode} failed`)
                appendLog(error)
                setDisabled(false)
                setIsWaiting(false)
                if (prompt.ResetAfterSend)
                    setInput(prompt.Input)
            }).then(response => {
                if (response.data.info && response.data.info.length > 0)
                    appendLogs(response.data.info)

                if (response.data.error) {
                    appendLog(response.data.error)
                    appendLog(`request ${requestCode} failed`)
                } else {
                    setInputTokens(inputTokens => inputTokens + response.data.usage.PromptTokens)
                    setOutputTokens(outputTokens => outputTokens + response.data.usage.CompletionTokens)
                    setCost(cost => cost + response.data.usage.Cost)
                    pushMessage('assistant', response.data.response, response.data.embedDescriptors, response.data.imageDescriptors)
                    appendLog(`request ${requestCode} finished`)
                }

                setDisabled(false)
                setIsWaiting(false)

                if (prompt.ResetAfterSend)
                    setInput(prompt.Input)
            })
        })
    }

    return (
        <Win
            title="Prompt Execution"
            keyProp={props.keyProp}
            center={true}
            w={950}
            h={660}
        >
            <div className={css.Body}>
                <div className={css.TopBar}>
                    <div className={css.Buttons}>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                setHistory([])
                                inputRef.current.focus()
                                setInputTokens(0)
                                setOutputTokens(0)
                                setCost(0.0)
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                savePrompt()
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                    <div className={css.MainArea}>
                        <div className={css.SideBar}>
                            <div className={css.PromptInfo}>
                                <PropertiesEditor
                                    properties={[
                                        {
                                            Name: "Name",
                                            Value: <TextInput
                                                css={css.InnerInput}
                                                text={prompt.Name}
                                                setText={text => setPrompt(prompt => { return {...prompt, Name: text} } )}
                                            />
                                        },
                                        {
                                            Name: "Model",
                                            Value: <Select
                                                options={models}
                                                selectedId={prompt.AiModel && prompt.AiModel.ID}
                                                optionKey="ID"
                                                option={prompt.AiModel}
                                                setSelectedId={setModelId}
                                                disabled={disabled}
                                            />
                                        },
                                        {
                                            Name: "Tokens",
                                            Value: <TextInput
                                                css={css.InnerInput}
                                                text={prompt.MaxTokens}
                                                setText={(value) => setPrompt(prompt => { return {...prompt, MaxTokens: parseInt(value)} })}
                                                onlyNumbers={true}
                                            />
                                        },
                                        {
                                            Name: "System",
                                            Value: <TextArea
                                                rows={6}
                                                css={css.InnerInput}
                                                text={prompt.System}
                                                setText={(value) => setPrompt(prompt => { return {...prompt, System: value} })}
                                            />
                                        },
                                        {
                                            Name: "Input",
                                            Value: <TextArea
                                                css={css.InnerInput}
                                                text={prompt.Input}
                                                rows={4}
                                                setText={(value) => setPrompt(prompt => {
                                                    setInput(value)
                                                    return {...prompt, Input: value}
                                                })}
                                            />
                                        },
                                        {
                                            Name: "Memory",
                                            Value: <CheckBox
                                                checkId="use-history"
                                                checked={prompt.HasMemory || false}
                                                setGeneralChecked={(checked) => {
                                                    setPrompt(prompt => {
                                                        return {...prompt, HasMemory: checked || false}
                                                    })
                                                }}
                                            />
                                        },
                                    ]}
                                />
                            </div>
                            <ContextEditor
                                promptId={prompt.ID}
                                context={prompt.Context && prompt.Context.Data}
                                setContext={setContext}
                            />
                        </div>
                        <div className={css.ChatBox}>
                            <div className={`${css.BlackLayout} ${css.Grid}`}>
                                <div className={css.LayoutContainer}>
                                    <div className={css.Loading}>
                                        { isWaiting &&
                                            <ColorRing
                                                visible={true}
                                                height="32"
                                                width="32"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                                /*colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}*/
                                                colors={['#787878', '#f8b26a', '#f47e60', 'rgba(235, 235, 245, 0.6)', 'rgba(235, 235, 245, 0.3)']}
                                            />
                                        }
                                    </div>
                                    <div className={css.ChatHistory}>
                                        {[...history].map((message, index) => {
                                            return <div key={`history-${index}`} className={css.Message}>
                                                <div className={css.PortraitContainer}>
                                                    <div className={css.Portrait}/>
                                                    <div className={css.RoleName}>
                                                        {message.role.charAt(0).toUpperCase() + message.role.slice(1)}
                                                    </div>
                                                </div>
                                                <div className={css.MessageContent}>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: indent(message.content)}}
                                                    />
                                                    { message.embeddingDistances && message.embeddingDistances.length > 0 &&
                                                        <div className={css.MessageEmbeddings}>
                                                            { message.embeddingDistances.map(descriptor => {
                                                                return <div key={uuid()} className={css.MessageImage}>
                                                                    <div className={css.EmbedHeader}>
                                                                        <ImEmbed/>
                                                                        <div className={css.EmbedName}>
                                                                            {descriptor.Name}
                                                                        </div>
                                                                        <div className={css.EmbedRate}>
                                                                            {(100 * descriptor.Distance).toFixed(2)}%
                                                                        </div>
                                                                    </div>
                                                                    <div className={css.EmbedDetails}>
                                                                        <div className={css.Algorithm}>
                                                                            {algorithmLabel[descriptor.Algorithm]}
                                                                        </div>
                                                                        <hr/>
                                                                        <div className={css.EmbedContent}>
                                                                            {cropResult(descriptor.Result)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                    { message.imageDescriptors && message.imageDescriptors.length > 0 &&
                                                        <div className={css.MessageEmbeddings}>
                                                            { message.imageDescriptors.map(descriptor => {
                                                                return <div key={uuid()} className={css.MessageEmbed}>
                                                                    <div className={css.EmbedHeader}>
                                                                        <BsImage/>
                                                                        <div className={css.EmbedName}>
                                                                            {descriptor.Name}
                                                                        </div>
                                                                    </div>
                                                                    <div className={css.ImageDetails}>
                                                                        <div className={css.Algorithm}>
                                                                            <img src={descriptor.Url}/>
                                                                        </div>
                                                                        <hr/>
                                                                        <div className={css.EmbedContent}>
                                                                            {cropResult(descriptor.Description)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        })}
                                        <div ref={bottomRef}>
                                        </div>
                                    </div>
                                    <div className={css.MiddleBar}>
                                        <CheckBox
                                            checked={prompt.ResetAfterSend}
                                            setGeneralChecked={(checked) => {
                                                setPrompt(prompt => {
                                                    return {...prompt, ResetAfterSend: checked}
                                                })
                                            }}
                                        />
                                        <label
                                            className={css.ResetInputAfterSendLabel}
                                            onClick={() => setPrompt(prompt => {
                                                return {...prompt, ResetAfterSend: !prompt.ResetAfterSend}
                                            })}
                                        >
                                            Reset input after send
                                        </label>
                                    </div>
                                    <div className={css.InputArea}>
                                        <TextArea
                                            refer={inputRef}
                                            text={input}
                                            setText={setInput}
                                            onKeyDown={e => {
                                                if (!e.shiftKey && e.code === 'Enter' && !disabled) {
                                                    ask()
                                                    e.preventDefault && e.preventDefault();
                                                    return false;
                                                }
                                            }}
                                        />
                                        <Button
                                            css={css.SendButton}
                                            onClick={() => ask()}
                                            disabled={disabled}
                                        >
                                            Ask
                                        </Button>
                                    </div>
                                </div>
                                <div className={css.UsageContainer}>
                                    <div className={css.UsageItem}>
                                        <div className={css.UsageLabel}>
                                            Input:
                                        </div>
                                        <div className={css.UsageValue}>
                                            {inputTokens}
                                        </div>
                                    </div>
                                    <div className={css.UsageItem}>
                                        <div className={css.UsageLabel}>
                                            Output:
                                        </div>
                                        <div className={css.UsageValue}>
                                            {outputTokens}
                                        </div>
                                    </div>
                                    <div className={css.UsageItem}>
                                        <div className={css.UsageLabel}>
                                            Cost:
                                        </div>
                                        <div className={css.UsageValue}>
                                            $ {cost.toFixed(4)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={css.LogTitle}>
                                Logs&nbsp;
                                <div
                                    className={css.ClearLogsIcon}
                                    onClick={() => downloadLog()}
                                >
                                    <AiOutlineSave/>
                                </div>
                                <div
                                    className={css.ClearLogsIcon}
                                    onClick={() => setLogs('')}
                                >
                                    <AiOutlineClear/>
                                </div>

                            </div>
                            <div className={css.Logs}>
                                <TextArea
                                    text={logs}
                                    disabled={true}
                                    refer={logsRef}
                                >

                                </TextArea>
                            </div>
                        </div>
                    </div>
            </div>
        </Win>
    );
}

export default PromptExecWin;
