import ExplorerWin from "../../ExplorerWin";
import React, {useState, useContext} from "react";
import {fetchValidations, deleteValidation} from "../../../../../../services/api/Validation";
import { Context } from "../../../../../../data/Context";

function ValidationsWin(props) {
    const {state, dispatch} = useContext(Context);

    const [users, setUsers] = useState([])
    function getData() {
        fetchValidations(dispatch).then(response => {
            setUsers(response.data.validations)
        })
    }

    function deleteRecord(id) {
        deleteValidation(dispatch, id).then(() => {
            getData()
        })
    }

    return <ExplorerWin
        {...props}
        data={users}
        getData={getData}
        deleteRecord={deleteRecord}
        addData={() => state.managerFunctions.openWin('NewValidationWin', { getData })}
        editRecord={(validationId) => state.managerFunctions.openWin('NewValidationWin', { getData, validationId })}
        title="Validations"
        headers={[
            { name: 'ID', style: { width: '10%' } },
            { name: 'Name', style: { width: '80%' } },
            { name: 'Actions', style: { width: '10%' } },
        ]}
    />
}

export default ValidationsWin
