import css from '../UtilitaryWins.module.scss'
import Win from '../../../../../library/win/Win'
import React, {useState, useContext, useEffect} from 'react'
import TextInput from '../../../../../library/textinput/TextInput'
import Button from '../../../../../library/button/Button'
import {Context} from '../../../../../../data/Context'
import TextArea from "../../../../../library/textarea/TextArea";
import { createValidation, fetchValidation, updateValidation } from '../../../../../../services/api/Validation'
import InputLabel from "../../../../../library/input_label/InputLabel";

function NewValidationWin(props) {
    const [name, setName] = useState('')
    const [sourceCode, setSourceCode] = useState("")
    const {state, dispatch} = useContext(Context);

    useEffect(() => {
        if (props.validationId && props.validationId !== 0)
            load()
    }, [props.validationId])

    function load() {
        fetchValidation(dispatch, props.validationId).then(response => {
            setName(response.data.Name)
            setSourceCode(response.data.SourceCode)
        })
    }

    function title() {
        return props.validationId && props.validationId !== 0 ? 'Edit Validation' : 'New Validation'
    }

    function saveValidation() {
        if (props.validationId) {
            updateValidation(dispatch, props.validationId, {
                name,
                sourceCode
            }).then(() => {
                props.getData()
                state.managerFunctions.closeWin(state.activeWin)
            })

            return;
        }

        createValidation(dispatch, {
            name,
            sourceCode
        }).then(() => {
            props.getData()
            state.managerFunctions.closeWin(state.activeWin)
        })
    }

    return (
        <Win
            title={title()}
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={355}
            w={500}
        >
            <div className={css.Body}>
                <InputLabel label="Name">
                    <TextInput
                        css={css.Input}
                        text={name}
                        setText={setName}
                    />
                </InputLabel>
                <InputLabel label="Source Code">
                    <TextArea
                        rows={14}
                        css={css.Input}
                        text={sourceCode}
                        setText={setSourceCode}
                    />
                </InputLabel>
                <div className={css.ButtonBar}>
                    <Button
                        onClick={() => {
                            saveValidation()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default NewValidationWin;
