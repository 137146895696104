import baseApi from './BaseApi';

const fetchScriptableN = (dispatch) => baseApi(dispatch).get("/scriptable")
const fetchScriptable = (dispatch, id) => baseApi(dispatch).get("/scriptable/" + id)
const createScriptable = (dispatch, data) => baseApi(dispatch).post("/scriptable", data)
const updateScriptable = (dispatch, id, data) => baseApi(dispatch).put("/scriptable/" + id, data)
const deleteScriptable = (dispatch, id) => baseApi(dispatch).delete("/scriptable/" + id)


export {
    fetchScriptable,
    fetchScriptableN,
    createScriptable,
    updateScriptable,
    deleteScriptable,
}
