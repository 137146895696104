import React from 'react'
import css from './ActionButton.module.scss'

function ActionButton(props) {
    return (
        <a
            className={css.ActionButton}
            onClick={props.onClick}
        >
            {props.icon}
        </a>
    );
}

export default ActionButton;
