import ExplorerWin from "../../ExplorerWin";
import React, {useState, useContext} from "react";
import {deleteScriptable, fetchScriptableN} from "../../../../../../services/api/Scriptable";
import { Context } from "../../../../../../data/Context";

function ScriptableWin(props) {
    const {state, dispatch} = useContext(Context)

    const [scriptable, setScriptable] = useState([])
    function getData() {
        fetchScriptableN(dispatch).then(response => {
            setScriptable(response.data.scriptable)
        })
    }

    function deleteRecord(id) {
        deleteScriptable(dispatch, id).then(() => {
            getData()
        })
    }

    return <ExplorerWin
        {...props}
        data={scriptable}
        getData={getData}
        deleteRecord={deleteRecord}
        addData={() => state.managerFunctions.openWin('NewScriptableWin', { getData })}
        editRecord={(scriptableId) => state.managerFunctions.openWin('NewScriptableWin', { getData, scriptableId })}
        title="Scriptable"
        headers={[
            { name: 'ID', style: { width: '10%' } },
            { name: 'Name', style: { width: '70%' } },
            { name: 'Actions', style: { width: '10%' } },
        ]}
    />
}

export default ScriptableWin
//export default React.memo(ScriptableWin);
