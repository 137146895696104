import css from '../UtilitaryWins.module.scss'
import Win from '../../../../../library/win/Win'
import React, {useState, useContext, useEffect} from 'react'
import TextInput from '../../../../../library/textinput/TextInput'
import Button from '../../../../../library/button/Button'
import {Context} from '../../../../../../data/Context'
import TextArea from "../../../../../library/textarea/TextArea";
import { createModel, fetchModel, updateModel } from '../../../../../../services/api/Model'
import InputLabel from "../../../../../library/input_label/InputLabel";

function NewModelWin(props) {
    const [name, setName] = useState('')
    const [pricePerInputK, setPricePerInputK] = useState("")
    const [pricePerOutputK, setPricePerOutputK] = useState("")
    const [description, setDescription] = useState("")
    const {state, dispatch} = useContext(Context);

    useEffect(() => {
        if (props.modelId && props.modelId !== 0)
            load()
    }, [props.modelId])

    function load() {
        fetchModel(dispatch, props.modelId).then(response => {
            setName(response.data.Name)
            setDescription(response.data.Description)
            setPricePerInputK(response.data.PricePerInputK.toString())
            setPricePerOutputK(response.data.PricePerOutputK.toString())
        })
    }

    function title() {
        return props.modelId && props.modelId !== 0 ? 'Edit Model' : 'New Model'
    }

    function saveModel() {
        if (props.modelId) {
            updateModel(dispatch, props.modelId, {
                name,
                description,
                pricePerK: parseFloat(pricePerInputK),
            }).then(() => {
                props.getData()
                state.managerFunctions.closeWin(state.activeWin)
            })

            return;
        }

        createModel(dispatch, {
            name,
            description,
            pricePerK: parseFloat(pricePerInputK),
        }).then(() => {
            props.getData()
            state.managerFunctions.closeWin(state.activeWin)
        })
    }

    return (
        <Win
            title={title()}
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={273}
        >
            <div className={css.Body}>
                <InputLabel label="Name">
                    <TextInput
                        css={css.Input}
                        text={name}
                        setText={setName}
                    />
                </InputLabel>
                <InputLabel label="Input Price (1k of Tokens)">
                    <TextInput
                        css={css.Input}
                        text={pricePerInputK}
                        setText={setPricePerInputK}
                    />
                </InputLabel>
                <InputLabel label="Output Price (1k of Tokens)">
                    <TextInput
                        css={css.Input}
                        text={pricePerOutputK}
                        setText={setPricePerOutputK}
                    />
                </InputLabel>
                <InputLabel label="Description">
                    <TextArea
                        rows={4}
                        css={css.Input}
                        text={description}
                        setText={setDescription}
                    />
                </InputLabel>
                <div className={css.ButtonBar}>
                    <Button
                        onClick={() => {
                            saveModel()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default NewModelWin;
