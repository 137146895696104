import baseApi from './BaseApi';

const fetchEmbedding = (dispatch, id) => baseApi(dispatch).get("/embeddings/" + id)
const createEmbedding = (dispatch, data) => baseApi(dispatch).post("/embeddings", data)
const updateEmbedding = (dispatch, id, data) => baseApi(dispatch).put("/embeddings/" + id, data)

export {
    fetchEmbedding,
    createEmbedding,
    updateEmbedding,
}
