import React from 'react'
import css from './VariableEdit.module.scss'
import TextInput from "../textinput/TextInput";

function VariableEdit(props) {
    return (
        <div className={`${css.PairBox} ${props.className}`} data-testid="pair-box">
            <div className={`${css.Left} ${css.Lane}`}>
                <div className={css.LeftText}>
                    { props.label }
                </div>
            </div>
            <div className={`${css.Right} ${css.Lane}`}>
                <TextInput
                    onlyNumbers={props.onlyNumbers}
                    css={css.RightText}
                    text={props.text}
                    setText={props.setText}
                    disabled={props.disabled}
                />
            </div>
        </div>
    )
}

export default VariableEdit
