import css from '../UtilitaryWins.module.scss'
import Win from '../../../../../library/win/Win'
import React, {useState, useContext, useEffect, useRef} from 'react'
import TextInput from '../../../../../library/textinput/TextInput'
import Button from '../../../../../library/button/Button'
import {Context} from '../../../../../../data/Context'
import { createScriptable, fetchScriptable, updateScriptable } from '../../../../../../services/api/Scriptable'
import InputLabel from "../../../../../library/input_label/InputLabel";
import Editor from "@monaco-editor/react";

function NewScriptableWin(props) {
    const [name, setName] = useState('')
    const [sourceCode, setSourceCode] = useState("")
    const {state, dispatch} = useContext(Context);
    const editorRef = useRef(null)

    useEffect(() => {
        if (props.scriptableId && props.scriptableId !== 0)
            load()
    }, [props.scriptableId])

    function load() {
        fetchScriptable(dispatch, props.scriptableId).then(response => {
            setName(response.data.Name)
            setSourceCode(response.data.SourceCode)
        })
    }

    function title() {
        return props.scriptableId && props.scriptableId !== 0 ? 'Edit Scriptable' : 'New Scriptable'
    }

    // eslint-disable-next-line no-unused-vars
    function handleEditorMount(editor, monaco) {
        editorRef.current = editor
    }

    function saveScriptable() {
        if (props.scriptableId) {
            updateScriptable(dispatch, props.scriptableId, {
                name,
                sourceCode: editorRef.current.getValue(),
            }).then(() => {
                props.getData()
                state.managerFunctions.closeWin(state.activeWin)
            })

            return;
        }

        createScriptable(dispatch, {
            name,
            sourceCode: editorRef.current.getValue(),
        }).then(() => {
            props.getData()
            state.managerFunctions.closeWin(state.activeWin)
        })
    }

    return (
        <Win
            title={title()}
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={500}
            w={600}
        >
            <div className={css.Body}>
                <InputLabel
                    label="Name"
                >
                    <TextInput
                        css={css.Input}
                        text={name}
                        setText={setName}
                    />
                </InputLabel>
                <InputLabel
                    label="Source Code"
                    bodyCss={css.CodeLabel}
                >
                    <Editor
                        value={sourceCode}
                        defaultLanguage="javascript"
                        theme="vs-dark"
                        height="100%"
                        onMount={handleEditorMount}
                    />
                </InputLabel>
                <div className={css.ButtonBar}>
                    <Button
                        onClick={() => {
                            saveScriptable()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default NewScriptableWin;
