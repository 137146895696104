import css from './ModelExecWin.module.scss'
import Win from '../../../../../library/win/Win'
import React, {useContext, useEffect, useRef, useState} from "react";
import TextArea from "../../../../../library/textarea/TextArea";
import Button from "../../../../../library/button/Button";
import {Context} from "../../../../../../data/Context";
import {executeModel, fetchModel, fetchModels} from "../../../../../../services/api/Model";

import VariableEdit from "../../../../../library/variable_edit/VariableEdit";
import VariableSelect from "../../../../../library/variable_select/VariableSelect";
import Spinner from "../../../../../library/spinner/Spinner";
import CheckBox from "../../../../../library/check_box/CheckBox";

function ModelExecWin(props) {
    const [input, setInput] = useState('')
    const [tokenLimit, setTokenLimit] = useState(0)
    const [model, setModel] = useState({})
    const [models, setModels] = useState([])
    const {dispatch} = useContext(Context)
    const [history, setHistory] = useState([])
    const inputRef = useRef(null)
    const bottomRef = useRef(null)
    const [disabled, setDisabled] = useState(false)
    const [isWaiting, setIsWaiting] = useState(false)
    const [useHistory, setUseHistory] = useState(false)

    useEffect(() => {
        getModel(props.modelId)

        fetchModels(dispatch).then(response => {
            setModels(response.data.models)
        })
    }, [])

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [history])

    function pushMessage(role, content) {
        setHistory(history => [...history, {
            role, content
        }])
    }

    function getModel(id) {
        setDisabled(true)
        fetchModel(dispatch, id).then(response => {
            setModel(response.data)
            setTokenLimit(100)
            setDisabled(false)
            inputRef.current.focus()
        })
    }

    function indent(text) {
        return text
            .replaceAll(/\n/g, '<br/>')
    }

    function ask() {
        if (input.length === 0) return

        setInput('')
        inputRef.current.focus()

        setDisabled(true)
        setIsWaiting(true)

        const inputList = useHistory ? [...history] : []
        pushMessage('user', input)

        executeModel(dispatch, model.ID, {
            input,
            history: inputList,
            maxTokens: parseInt(tokenLimit.toString()),
            context: {}
        }).catch(error => {
            console.log(error)
            setDisabled(false)
            setIsWaiting(false)
        }).then(response => {
            pushMessage('assistant', response.data.response || response.data.error)
            setDisabled(false)
            setIsWaiting(false)
        })
    }

    return (
        <Win
            title="Model Execution"
            keyProp={props.keyProp}
            center={true}
            w={700}
            h={500}
            modal={props.modal}
        >
            <div className={css.Body}>
                <div className={css.MiddleContainer}>
                    <div className={css.PromptInfo}>
                        <VariableSelect
                            label="Model"
                            option={model}
                            setSelectedId={getModel}
                            disabled={disabled}
                            options={models}
                            selectedId={model.ID}
                            optionKey="ID"
                        >
                            { tokenLimit }
                        </VariableSelect>

                    </div>
                    <div className={css.PromptInfo}>
                        <VariableEdit
                            label="Max Tokens"
                            text={tokenLimit}
                            setText={setTokenLimit}
                            onlyNumbers={true}
                        >
                            { tokenLimit }
                        </VariableEdit>
                    </div>
                    <CheckBox
                        checkId="use-history"
                        checked={useHistory}
                        setChecked={setUseHistory}
                        label="Memorize"
                    />
                    <div className={css.Buttons}>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                setHistory([])
                                inputRef.current.focus()
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                const rawContent = {
                                    tokenLimit,
                                    model,
                                    history
                                }

                                const json = JSON.stringify(rawContent)
                                const file = new Blob([rawContent])
                                console.log(json)
                                console.log(file)
                            }}
                        >
                            Export
                        </Button>
                    </div>
                </div>
                <div className={css.ChatHistory}>
                    <div className={css.Loading}>
                        { isWaiting &&
                            <Spinner/>
                        }
                    </div>
                    {history.map((message, index) => {
                        return <div key={`history-${index}`} className={css.Message}>
                            <div className={css.PortraitContainer}>
                                <div className={css.Portrait}/>
                                <div className={css.RoleName}>
                                    {message.role.charAt(0).toUpperCase() + message.role.slice(1)}
                                </div>
                            </div>
                            <div className={css.MessageContent}>
{/*                                <Highlight innerHTML={true}>
                                    {"html with multiple code snippets"}
                                </Highlight>*/}
                                <span
                                    dangerouslySetInnerHTML={{__html: indent(message.content)}}
                                />
                            </div>
                        </div>
                    })}
                    <div ref={bottomRef}>
                    </div>
                </div>
                <div className={css.InputArea}>
                    <TextArea
                        refer={inputRef}
                        text={input}
                        setText={setInput}
                        onKeyDown={e => {
                            if (!e.shiftKey && e.code === 'Enter' && !disabled) {
                                ask()
                                e.preventDefault && e.preventDefault();
                                return false;
                            }
                        }}
                    />
                    <Button
                        css={css.SendButton}
                        onClick={() => ask()}
                        disabled={disabled}
                    >
                        Ask
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default ModelExecWin;
