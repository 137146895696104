import ExplorerWin from "../../ExplorerWin";
import React, {useState, useContext} from "react";
import { deleteUnitTest, fetchUnitTests } from "../../../../../../services/api/UnitTest";
import { Context } from "../../../../../../data/Context";

function UnitTestsWin(props) {
    const { state, dispatch } = useContext(Context)

    const [unitTests, setUnitTests] = useState([])

    function getData() {
        fetchUnitTests(dispatch).then(response => {
            setUnitTests(response.data.unitTests)
        })
    }

    function deleteRecord(id) {
        deleteUnitTest(dispatch, id).then(() => {
            getData()
        })
    }

    return <ExplorerWin
        {...props}
        data={unitTests}
        getData={getData}
        deleteRecord={deleteRecord}
        addData={() => state.managerFunctions.openWin('NewTestWin', { getData })}
        editRecord={(unitTestId) => state.managerFunctions.openWin('NewTestWin', { getData, unitTestId })}
        title="Unit Tests"
        headers={[
            { name: 'ID', style: { width: '10%' } },
            { name: 'Name', style: { width: '30%' } },
            { name: 'ActionType', style: { width: '40%' } },
            { name: 'ActionID', style: { width: '10%' } },
            { name: 'Actions', style: { width: '10%'} }
        ]}
    />
}

export default UnitTestsWin
