import React from 'react'
import css from './VariableSelect.module.scss'

function VariableSelect(props) {
    return (
        <div className={`${css.PairBox} ${props.className}`} data-testid="pair-box">
            <div className={`${css.Left} ${css.Lane}`}>
                <div className={css.LeftText}>
                    { props.label }
                </div>
            </div>
            <div className={`${css.Right} ${css.Lane}`}>
                <select className={css.Select}
                    onChange={e => props.setSelectedId(e.target.value)}
                    value={props.selectedId}
                >
                    { props.options && props.options.map(option => {
                        return <option
                            key={`model-${option[props.optionKey]}`}
                            value={option[props.optionKey]}
                            /*selected={option[props.optionKey] === props.selectedId}*/
                        >
                            {option.Name}
                        </option>
                    })}
                </select>
            </div>
        </div>
    )
}

export default VariableSelect
