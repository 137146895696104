import css from '../../UtilitaryWins.module.scss'
import localCss from './ContextScriptWin.module.scss'
import Win from '../../../../../../library/win/Win'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Button from '../../../../../../library/button/Button'
import {Context} from '../../../../../../../data/Context'
import Editor from '@monaco-editor/react';


function ContextScriptWin(props) {
    const {state} = useContext(Context);
    const [script, setScript] = useState('')
    const editorRef = useRef(null)

    function saveScript() {
        props.setScript(editorRef.current.getValue())
        state.managerFunctions.closeWin(state.activeWin)
    }

    useEffect(() => {
        setScript(props.script)
    }, [props.setScript]);

    // eslint-disable-next-line no-unused-vars
    function handleEditorMount(editor, monaco) {
        editorRef.current = editor
    }

    return (
        <Win
            title="Script Editor"
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={500}
            w={600}
        >
            <div className={`${css.Body} ${localCss.Body}`}>
                <Editor
                    defaultLanguage="javascript"
                    value={script}
                    theme="vs-dark"
                    onMount={handleEditorMount}
                />
                <div className={css.ButtonBar}>
                    <Button
                        onClick={() => {
                            saveScript()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default ContextScriptWin;
