import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../data/Context";
import css from './Flash.module.scss'
import { GoAlert } from 'react-icons/go'

function Flash() {
  const { state, dispatch } = useContext(Context);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (state.flash && state.flash.length) {
      setNotifications((notifications) => {
        const flash = [
          ...notifications,
          ...state.flash,
        ];

        state.flash.forEach((message) => {
          setTimeout(() => {
            setNotifications((notifications) =>
              notifications.filter(notification =>
                  notification.uuid !== message.uuid
              )
            );
          }, 3000);
        });

        dispatch({ type: "CLEAR_FLASH" });

        return flash;
      });
    }
  }, [state.flash]);

  return (
    <div>
      {notifications.map((notification) => (
        <div key={`notification-${notification.uuid}`} className={css.Notification}>
          <div className={css.Title}>
            <GoAlert className={css.Icon} />
            {notification.title}
          </div>
          <div className={css.Content}>
            {notification.content}
          </div>
          <div className={css.TimeBar}>

          </div>
        </div>
      ))}
    </div>
  );
}

export default Flash;
