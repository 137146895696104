import React from 'react'
import css from './Select.module.scss'

function Select(props) {
    return (
        <div className={css.Container}>
            <select className={css.Select}
                    onChange={e => props.setSelectedId(e.target.value)}
                    value={props.selectedId}
            >
                { props.options && props.options.map(option => {
                    return <option
                        key={`model-${option[props.optionKey]}`}
                        value={option[props.optionKey]}
                        /*selected={option[props.optionKey] === props.selectedId}*/
                    >
                        {option.Name}
                    </option>
                })}
            </select>
        </div>
    )
}

export default Select
