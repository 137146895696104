import css from '../../UtilitaryWins.module.scss'
import Win from '../../../../../../library/win/Win'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Button from '../../../../../../library/button/Button'
import {Context} from '../../../../../../../data/Context'
import TextInput from "../../../../../../library/textinput/TextInput";
import localCss from './ContextEmbeddingWin.module.scss'
import {AiFillFolderOpen} from "react-icons/ai";
import uuid from "react-uuid";
import {createEmbedding, fetchEmbedding, updateEmbedding} from "../../../../../../../services/api/Embedding";
import PropertiesEditor from "../../../../../../library/properties_editor/PropertiesEditor";
import Select from "../../../../../../library/select/Select";
import {ColorRing} from "react-loader-spinner";

function ContextEmbeddingWin(props) {
    const {state} = useContext(Context);
    const [promptId, setPromptId] = useState(0)
    const [embeddingId, setEmbeddingId, refEmbeddingId] = useStateAndRef(0)
    const [embedding, setEmbedding] = useState('')
    const [splitter, setSplitter, refSplitter] = useStateAndRef('\\n')
    const [sectionSize, setSectionSize] = useState(1)
    const [sectionCount, setSectionCount] = useState(1)
    const [algorithm, setAlgorithm] = useState(0)
    const [minRate, setMinRate] = useState(50.0)
    const [status, setStatus, refStatus] = useStateAndRef(0)
    const fileInputRef = useRef(null)
    const {dispatch} = useContext(Context)

    const statusLabel = {
        0: "changed",
        1: "processing",
        2: "processed"
    }

    const statusLabelColor = {
        0: localCss.Status0,
        1: localCss.Status1,
        2: localCss.Status2,
    }

    function saveEmbedding() {
        if (embeddingId === 0) {
            createEmbedding(dispatch, { embeddingID: embeddingId, promptID: promptId, value: embedding, sectionSize, splitter, minRate, algorithm }).then(response => {
                props.setEmbedding(response.data.embedding.ID, splitter, sectionSize, sectionCount, minRate, algorithm)
                setEmbeddingId(response.data.embedding.ID)
                console.log('create')
            })
        } else {
            updateEmbedding(dispatch, embeddingId, { value: embedding, sectionSize, splitter, minRate, algorithm }).then(response => {
                props.setEmbedding(response.data.embedding.ID, splitter, sectionSize, sectionCount, minRate, algorithm)
                setStatus(1)
                console.log('update')
            })
        }

        //state.managerFunctions.closeWin(state.activeWin)
    }

    function monitor() {
        if (refStatus.current === 1)
            getEmbedding()
    }

    function useStateAndRef(initial) {
        const [value, setValue] = useState(initial);
        const valueRef = useRef(value);
        valueRef.current = value;
        return [value, setValue, valueRef];
    }

    useEffect(() => {
        setPromptId(props.promptId)
        setEmbeddingId(props.embeddingId)
        setSplitter(props.splitter || '\n')
        setSectionSize(props.sectionSize || 1)
        setSectionCount(props.sectionCount || 1)
        setMinRate(props.minRate || 50.0)
        setAlgorithm(props.algorithm || 0)

        const intervalId = setInterval(monitor, 5000)

        return () => {
            clearInterval(intervalId)
        };
    }, [])

    useEffect(() => {
        if (!embeddingId)
            return

        getEmbedding()
    }, [embeddingId]);

    function getEmbedding() {
        return fetchEmbedding(dispatch, refEmbeddingId.current).then(response => {
            if (response.data.embedding.Status !== 1) {
                setEmbedding(response.data.chunks.map(chunk => chunk.Content).join(refSplitter.current))
            }
            setStatus(response.data.embedding.Status)
            return response
        })
    }

    function slice() {
        if (!embedding)
            return []

        let lines = embedding.split(splitter)
        lines = lines.filter(line => line.length > 0)
        const chunks = []

        for (let i = 0; i < lines.length; i += sectionSize) {
            const chunk = lines.slice(i, i + sectionSize)
            chunks.push(chunk)
        }

        return chunks
    }

    function onFileChange(e){
        const file = e.target.files[0];
        e.target.value = ''

        if (!file)
            return

        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");

        reader.onload = function (evt) {
            setEmbedding(evt.target.result);
        }

        reader.onerror = function(evt){
            console.log(evt);
        }
    }

    return (
        <Win
            title="Embedding Editor"
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={463}
            w={750}
        >
            <div className={css.Body}>
                <div className={css.ButtonBar}>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                    <Button
                        disabled={status !== 0 && status !== 2}
                        onClick={() => {
                            (status === 0 || status === 2) && saveEmbedding()
                        }}

                    >
                        Process
                    </Button>
                    <div className={localCss.StatusContainer}>
                        <div className={`${localCss.StatusValue} ${statusLabelColor[status]}`}>
                            <span className={localCss.StatusLabel}>
                                status:
                            </span>
                            {statusLabel[status]}
                            {status === 1 && <ColorRing
                                visible={true}
                                height="14"
                                width="14"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />}
                        </div>
                    </div>

                </div>
                <div className={localCss.MainContainer}>
                    <div className={localCss.Sidebar}>
                        <PropertiesEditor
                            properties={[
                                {
                                    Name: "File",
                                    Value: <Button
                                        onClick={() => fileInputRef.current.click()}
                                        disabled={status === 1}
                                    >
                                        <AiFillFolderOpen/>
                                        Select
                                    </Button>
                                },
                                {
                                    Name: "Splitter",
                                    Value: <TextInput
                                        text={splitter && splitter.replaceAll('\n', '\\n')}
                                        setText={(text) => {
                                            //text = new RegExp(text)
                                            setSplitter(text.replaceAll('\\n', '\n'))
                                        }}
                                        css={localCss.InnerInput}
                                    />
                                },
                                {
                                    Name: "Block Size",
                                    Value: <TextInput
                                        text={sectionSize}
                                        setText={text => setSectionSize(parseInt(text))}
                                        css={localCss.InnerInput}
                                        onlyNumbers={true}
                                    />
                                },
                                {
                                    Name: "Block Count",
                                    Value: <TextInput
                                        text={sectionCount}
                                        setText={text => setSectionCount(parseInt(text))}
                                        css={localCss.InnerInput}
                                        onlyNumbers={true}
                                    />
                                },
                                {
                                    Name: "Min Rate (%)",
                                    Value: <TextInput
                                        text={minRate}
                                        setText={text => setMinRate(parseFloat(text))}
                                        css={localCss.InnerInput}
                                        onlyNumbers={true}
                                    />
                                },
                                {
                                    Name: "Algorithm",
                                    Value: <Select
                                        options={[
                                            { id: "0", Name: "Cosine Similarity" },
                                            { id: "1", Name: "Inner Product" },
                                            { id: "2", Name: "L2 Distance" },
                                        ]}
                                        selectedId={algorithm}
                                        optionKey="id"
                                        option={algorithm.toString()}
                                        setSelectedId={(id) => setAlgorithm(parseInt(id))}
                                        disabled={status === 1}
                                    />
                                },
                            ]}
                        />
                    </div>
                    <div className={localCss.FileContainer}>
                        <input
                            type="file"
                            accept="*"
                            style={{ display: "none" }}
                            id="fileInput"
                            ref={fileInputRef}
                            onInput={onFileChange}
                        />
                        <div className={localCss.FileContainer}>
                            <div className={localCss.FileBody}>
                                { slice().map((chunk,i) => {
                                    return <div key={uuid()} className={i % 2 === 0 ? localCss.EvenRow : localCss.OddRow}>
                                        {chunk.map((row) => {
                                            return <div key={uuid()}>
                                                {row}
                                            </div>
                                        })}</div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Win>
    );
}

export default ContextEmbeddingWin;
