import React, {useEffect} from 'react'
import css from './LazyTextInput.module.scss'
import {useState} from 'react'

function LazyTextInput(props) {
    const [internalText, setInternalText] = useState('')
    const focusOnSelect = props.focusOnSelect || false

    useEffect(() => {
        setInternalText(props.text)
    }, [props.text]);
    const onClick = () => {}
    const onKeyPress = props.onlyNumbers ? (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    } : () => {}

    return (
        <input
            type={props.password ? 'password' : 'text'}
            data-testid="text-input"
            className={`${css.TextInput} ${props.css}`}
            value={internalText}
            onChange={e => setInternalText(e.target.value)}
            onBlur={e => props.setText(e.target.value)}
            placeholder={props.placeholder || ''}
            ref={props.ref}
            autoFocus={props.autoFocus}
            onClick={props.onClick || onClick}
            disabled={props.disabled}
            onKeyDown={e => {
                e.key === 'Enter' ? e.target.blur() : null
            }}
            pattern={props.pattern}
            onFocus={focusOnSelect ? (e) => { e.target.select() } : () => {}}
            onKeyPress={onKeyPress}
        />
    )
}

export default LazyTextInput
