import axios from "axios";
import Cookies from "js-cookie";
//import uuid from "react-uuid";

const baseApi = (dispatch) => {
  const object = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 240000,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });

  object.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error)
        if (error.response && error.response.status === 401){
            dispatch({ type: 'SET_LOGGED_IN', loggedIn: false });
            Cookies.remove('Authorization');
            location.reload();
        }
    }
  );

  return object;
};

export default baseApi;
