import React from 'react'
import css from './Button.module.scss'

function Button(props) {
    return (
        <div
            className={`${css.Button} ${props.css} ${props.disabled && css.Disabled}`}
            onClick={props.disabled ? (() => {}) : props.onClick}
        >
            {props.children}
        </div>
    );
}

export default Button;
