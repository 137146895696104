import React from 'react'
import css from './CheckBox.module.scss'

function CheckBox(props) {
    return (
        <div className={css.Container}>
            <input
                id={`check-${props.checkId}`}
                type="checkbox"
                checked={props.checked}
                className={css.Checkbox}
                onClick={() => {
                    props.setGeneralChecked && props.setGeneralChecked(!props.checked)
                    props.setChecked && props.setChecked(checked => !checked)

                }}
                onChange={() => {}}
            />
            <label htmlFor={`check-${props.checkId}`}>
                {props.label}
            </label>
        </div>

    );
}

export default CheckBox;
