import React from 'react'
import css from './Menu.module.scss'

function Menu(props) {
    return (
        <div className={css.Menu}>
            {props.children}
            <div className={css.RightContainer}>
                {props.rightContainer}
            </div>
        </div>
    )
}

export default Menu;
