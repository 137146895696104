import baseApi from './BaseApi';

const fetchValidations = (dispatch) => baseApi(dispatch).get("/validations")
const fetchValidation = (dispatch, id) => baseApi(dispatch).get("/validations/" + id)
const createValidation = (dispatch, data) => baseApi(dispatch).post("/validations", data)
const updateValidation = (dispatch, id, data) => baseApi(dispatch).put("/validations/" + id, data)
const deleteValidation = (dispatch, id) => baseApi(dispatch).delete("/validations/" + id)

export {
    fetchValidations,
    fetchValidation,
    createValidation,
    updateValidation,
    deleteValidation,
}
