import AboutWin from '../../application/win/AboutWin/AboutWin'
import LoginWin from '../../application/win/LoginWin/LoginWin'
import UsersWin from "../../application/win/ExplorerWin/Instances/users/UsersWin";
import ModelsWin from "../../application/win/ExplorerWin/Instances/models/ModelsWin";
import ValidationsWin from "../../application/win/ExplorerWin/Instances/validations/ValidationsWin";
import ScriptableWin from "../../application/win/ExplorerWin/Instances/scriptable/ScriptableWin";
import PromptsWin from "../../application/win/ExplorerWin/Instances/prompts/PromptsWin";
import ChainsWin from "../../application/win/ExplorerWin/Instances/chains/ChainsWin";
import ModelExecWin from "../../application/win/ExplorerWin/Instances/models/ModelExecWin";
import PromptExecWin from "../../application/win/ExplorerWin/Instances/prompts/PromptExecWin";
import NewModelWin from '../../application/win/ExplorerWin/Instances/models/NewModelWin';
import NewValidationWin from '../../application/win/ExplorerWin/Instances/validations/NewValidationWin';
import NewScriptableWin from '../../application/win/ExplorerWin/Instances/scriptable/NewScriptableWin'
import ContextStringWin from "../../application/win/ExplorerWin/Instances/context/StringEditor/ContextStringWin";
import ContextScriptWin from "../../application/win/ExplorerWin/Instances/context/ScriptEditor/ContextScriptWin";
import NewPromptWin from "../../application/win/ExplorerWin/Instances/prompts/NewPromptWin";
import UnitTestsWin from '../../application/win/ExplorerWin/Instances/tests/TestsWin';
import NewTestWin from '../../application/win/ExplorerWin/Instances/tests/NewTestWin';
import GSLWin from "../../application/win/GSLWin/GSLWin";
import ContextEmbeddingWin from "../../application/win/ExplorerWin/Instances/context/EmbeddingEditor/ContextEmbeddingWin";
import ContextImageWin from "../../application/win/ExplorerWin/Instances/context/ImageEditor/ContextImageWin";

const WinMap = {
    AboutWin,
    LoginWin,
    UsersWin,
    ModelsWin,
    ValidationsWin,
    ScriptableWin,
    PromptsWin,
    ChainsWin,
    ModelExecWin,
    PromptExecWin,
    NewModelWin,
    NewValidationWin,
    NewScriptableWin,
    ContextStringWin,
    ContextScriptWin,
    NewPromptWin,
    UnitTestsWin,
    NewTestWin,
    GSLWin,
    ContextEmbeddingWin,
    ContextImageWin,
}

export default WinMap
