import React from 'react'
import css from './TextArea.module.scss'
import {useState} from 'react'

function TextArea(props) {
    const [internalText, setInternalText] = useState('')
    const text = props.text || internalText
    const setText = props.setText || setInternalText
    const onKeyDown = props.onKeyDown || (() => {})

    return (
        <textarea
            ref={props.refer}
            data-testid="text-area"
            className={`${css.TextArea} ${props.css}`}
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyDown={onKeyDown}
            readOnly={props.readOnly || false}
            rows={props.rows || 2}
            disabled={props.disabled || false}
        />
    )
}

export default TextArea
