import ExplorerWin from "../../ExplorerWin";
import React, {useState, useContext} from "react";
import {deleteUser, fetchUsers} from "../../../../../../services/api/User";
import { Context } from "../../../../../../data/Context";

function UsersWin(props) {
    const { dispatch } = useContext(Context)

    const [users, setUsers] = useState([])

    function getData() {
        fetchUsers(dispatch).catch(error => {
            console.log(error)
        }).then(response => {
            setUsers(response.data.users)
        })
    }

    function deleteRecord(id) {
        deleteUser(id).then(() => {
            getData()
        })
    }

    return <ExplorerWin
        {...props}
        data={users}
        getData={getData}
        deleteRecord={deleteRecord}
        title="Users"
        headers={[
            { name: 'ID', style: { width: '10%' } },
            { name: 'Name', style: { width: '25%' } },
            { name: 'Email', style: { width: '60%' } },
            { name: 'Actions', style: { width: '10%' } },
        ]}
    />
}

export default UsersWin
