import React, {createContext, useReducer} from 'react'

const initialState = {
    managerFunctions: {},
    grabItem: null,
    activeWin: null,
    winOffsetX: 0,
    winOffsetY: 0,
    loggedIn: false,
    superUser: false,
    flash: []
};

function contextReducer(state, action) {
    switch (action.type) {
        case "SET_MANAGER_FUNCTIONS":
            return { ...state, managerFunctions: action.managerFunctions }
        case 'SET_GRAB_ITEM':
            return { ...state, grabItem: action.grabItem }
        case 'SET_WIN_OFFSET':
            return { ...state, winOffsetX: action.winOffsetX, winOffsetY: action.winOffsetY }
        case 'SET_ACTIVE_WIN':
            if (!action.activeWin)
                return {...state, activeWin: null}

            if (state.activeWin == null || state.activeWin.dataset.isModal !== 'true') {
                return {...state, activeWin: action.activeWin}
            } else {
                return {...state}
            }
        case 'SET_LOGGED_IN':
            return { ...state, loggedIn: action.loggedIn }
        case 'CLEAR_FLASH':
            return { ...state, flash: [] }
        case 'FLASH':
            return { ...state, flash: [...state.flash, action.message] }
        case 'SET_SUPER_USER':
            return { ...state, superUser: action.superUser, loggedIn: true }
        default:
            return state;
    }
}

const Context = createContext();

function ContextProvider(props){
    const [state, dispatch] = useReducer(contextReducer, initialState);
    const value = { state, dispatch };

    return <Context.Provider value={value} {...props} />;
}

export {Context, ContextProvider}
