import css from '../../UtilitaryWins.module.scss'
import Win from '../../../../../../library/win/Win'
import React, {useContext, useEffect, useState} from 'react'
import Button from '../../../../../../library/button/Button'
import {Context} from '../../../../../../../data/Context'
import TextArea from "../../../../../../library/textarea/TextArea";
import InputLabel from "../../../../../../library/input_label/InputLabel";

function ContextStringWin(props) {
    const {state} = useContext(Context);
    const [string, setString] = useState('')

    function saveString() {
        props.setString(string)
        state.managerFunctions.closeWin(state.activeWin)
    }

    useEffect(() => {
        setString(props.string)
    }, [props.string]);

    return (
        <Win
            title="String Editor"
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={302}
            w={500}
        >
            <div className={css.Body}>
                <InputLabel label="Value">
                    <TextArea
                        rows={14}
                        css={css.Input}
                        text={string}
                        setText={setString}
                    />
                </InputLabel>
                <div className={css.ButtonBar}>
                    <Button
                        onClick={() => {
                            saveString()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default ContextStringWin;
