import React from 'react'
import css from './App.module.scss'
//import Logo from './components/library/logo/Logo'
import Manager from './components/library/manager/Manager'
import MenuBar from './components/application/menu/MenuBar'
import { ContextProvider } from './data/Context'

function App() {
  return (
    <div className={css.MainContainer}>
        <ContextProvider>
            {/*MENU*/}
            <MenuBar/>

            {/*SOME WINDOW*/}
            <Manager />

            {/*LOGO*/}
            {/*<Logo/>*/}
        </ContextProvider>
    </div>
  );
}

export default App;
