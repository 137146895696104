import css from './LoginWin.module.scss'
import Win from '../../../library/win/Win'
import React, {useContext, useRef, useState} from 'react'
import TextInput from "../../../library/textinput/TextInput";
import Button from "../../../library/button/Button";
import img from '../../../../assets/images/login.png'
import {sessionLogin} from "../../../../services/api/Session";
import {Context} from "../../../../data/Context";

function LoginWin({ keyProp }) {
    const [email, setEmail] = useState("normaluser@email.com")
    const [password, setPassword] = useState("")
    const [enableInputs, setEnableInputs] = useState(true)
    const {state, dispatch} = useContext(Context)
    const winRef = useRef()
    const [errored, setErrored] = useState(false)
    const performLogin = () => {
        setEnableInputs(false)

        sessionLogin(email, password)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.user.super) {
                        dispatch({type: 'SET_SUPER_USER', superUser: true})
                    } else {
                        dispatch({type: 'SET_LOGGED_IN', loggedIn: true})
                    }
                    state.managerFunctions.closeWin(state.activeWin)
                }

                setEnableInputs(true)
        }).catch(() => {
            setErrored(true)
            setEnableInputs(true)
        })
    }

    return (
        <Win
            title="Login"
            className={css.Body}
            keyProp={keyProp}
            modal={true}
            center={true}
            winRef={winRef}
            h={370}
        >
            <img className={css.Img} src={img}/>
            <div className={css.InputContainer}>
                <div className={css.Label}>email</div>
                <TextInput
                    autoFocus={true}
                    text={email}
                    setText={setEmail}
                    disabled={!enableInputs}
                    onKeyDown={e => e.key === 'Enter' && performLogin()}
                />
            </div>

            <div className={css.InputContainer}>
                <div className={css.Label}>password</div>
                <TextInput
                    password={true}
                    text={password}
                    setText={setPassword}
                    disabled={!enableInputs}
                    onKeyDown={e => e.key === 'Enter' && performLogin()}
                />
            </div>

            { errored && <div className={css.Error}>
                Invalid credentials!
            </div> }

            <div className={css.ButtonContainer}>
                <Button onClick={performLogin}>
                    login
                </Button>
            </div>

        </Win>
    );
}

export default LoginWin
