import React, {useContext} from 'react'
import Menu from '../../../components/library/menu/Menu'
import MenuItem from '../../../components/library/menu/MenuItem'
import SubMenu from '../../../components/library/menu/SubMenu'
import {Context} from '../../../data/Context'
import { BsFiletypeTxt } from 'react-icons/bs'
import { BiHelpCircle} from 'react-icons/bi'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import Button from "../../library/button/Button";
import css from "./MenuBar.module.scss"
import Cookies from "js-cookie";
import {SERVER} from "../../../services/api/Const";

function MenuBar() {
    const {state} = useContext(Context);

    return (
        state.loggedIn && <Menu rightContainer={<div>
            <Button
                css={css.LogoutButton}
                onClick={() => {
                    Cookies.remove('Authorization');
                    location.reload();
                }}
            >
                Log-out
            </Button>
        </div>}>
            <MenuItem
                text="MENU"
                bold={true}
                iconSvg={<BsFiletypeTxt/>}
            >
                <SubMenu>
                    {state.superUser && <MenuItem
                        text="Users"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('UsersWin')
                        }}
                    />}
                    <MenuItem
                        text="Models"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('ModelsWin')
                        }}
                    />
                    {state.superUser && <MenuItem
                        text="Validations"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('ValidationsWin')
                        }}
                    />}
                    <MenuItem
                        text="Prompts"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('PromptsWin')
                        }}
                    />
                    {state.superUser && <MenuItem
                        text="Scriptable"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('ScriptableWin')
                        }}
                    />}
                    <MenuItem
                        text="GSL Console"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('GSLWin')
                        }}
                    />
                    <MenuItem
                        text="API"
                        iconSvg={null}
                        onClick={() => {
                            window.open(SERVER + '/docs/index.html', '_blank');
                        }}
                    />
                    {state.superUser && <MenuItem
                        text="Chains"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('ChainsWin')
                        }}
                    />}
                    <MenuItem
                        text="Tests"
                        iconSvg={null}
                        onClick={() => {
                            state.managerFunctions.openWin('UnitTestsWin')
                        }}
                    />
                </SubMenu>
            </MenuItem>
            <MenuItem
                text="HELP"
                bold={true}
                iconSvg={<BiHelpCircle/>}
            >
                <SubMenu>
                    <MenuItem
                        text="About"
                        iconSvg={<AiOutlineInfoCircle/>}
                        onClick={() => {
                            state.managerFunctions.openWin('AboutWin')
                        }}
                    />
                </SubMenu>
            </MenuItem>
        </Menu>
    );
}

export default MenuBar;
