import React, {useContext, useState} from 'react'
import css from './ContextEditor.module.scss'
import {AiFillDelete, AiOutlineNumber} from "react-icons/ai";
import {IoIosAdd, IoIosSettings} from 'react-icons/io'
import CheckBox from "../check_box/CheckBox";
import LazyTextInput from "../lazy_text_input/LazyTextInput";
import Button from "../button/Button";
import {Context} from "../../../data/Context";
import {BiText} from "react-icons/bi";
import {RiCheckboxMultipleFill} from 'react-icons/ri'
import {ImEmbed} from "react-icons/im";
import {BsCardImage} from "react-icons/bs";
import {DiJavascript1} from "react-icons/di";
import {FiEdit} from "react-icons/fi";

function ContextEditor(props) {
    const [addMenuVisibility, setAddMenuVisibility] = useState(false)
    const {state} = useContext(Context);

    function keyInput(index) {
        return <LazyTextInput
            text={props.context[index].name}
            setText={(text) => {
                const newContext = [...props.context]
                newContext[index].name = text
                props.setContext(newContext)
            }}
            focusOnSelect={true}
        />
    }

    function deleteKey(index) {
        const newContext = [...props.context]
        newContext.splice(index, 1)
        props.setContext(newContext)
    }

    function booleanInput(index) {
        return <CheckBox
            checked={props.context[index].value}
            setGeneralChecked={(checked) => {
                const newContext = [...props.context]
                newContext[index].value = checked
                props.setContext(newContext)
            }}
        />
    }

    function scriptInput() {
        return <div></div>
    }

    function embeddingInput() {
        return <div></div>
    }

    function readOnlyInput(index) {
        return <LazyTextInput
            text={props.context[index].value.toString().replaceAll("\n", "\\n")}
            disabled={true}
        />
    }

    function textInput(index, onlyNumbers = false) {
        return <LazyTextInput
            text={props.context[index].value.toString().replaceAll("\n", "\\n")}
            setText={(text) => {
                const newContext = [...props.context]
                newContext[index].value = text.replaceAll("\\n", "\n")
                props.setContext(newContext)
            }}
            onlyNumbers={onlyNumbers}
            focusOnSelect={true}
        />
    }

    function variableExists(name) {
        return props.context.filter(variable => variable.name === name).length > 0
    }

    const defaultIcons = {
        'number': <AiOutlineNumber/>,
        'string': <BiText/>,
        'boolean': <RiCheckboxMultipleFill/>,
        'function': <DiJavascript1/>,
        'embedding': <ImEmbed/>,
        'callback': <IoIosSettings/>,
        'image': <BsCardImage/>
    }

    function addVariable(kind) {
        const baseName = kind
        let name = baseName

        let exists = variableExists(name)

        let number = 0;
        while (exists) {
            name = baseName + `_${number++}`
            exists = variableExists(name)
        }

        const newContext = [...props.context]
        const defaultValues = {
            'number': 0,
            'string': '',
            'boolean': false,
            'function': 'function main(context, input) {\n}\n',
            'embedding': '',
            'callback': 'function main(context, input, response) {\n    console.log("This is a callback!")\n}\n',
            'image': '',
        }

        newContext.push({ name: name, type: kind, value: defaultValues[kind]})
        props.setContext(newContext)
        setAddMenuVisibility(false)
    }

    return (
        <div
            className={css.ContextEditorContainer}
        >
            <table
                className={`${css.PropertiesEditor} ${props.className}`}
                data-testid="properties-editor"
                onClick={() => setAddMenuVisibility(false)}
            >
                <thead>
                <tr className={css.Headers}>
                    <th>&nbsp;</th>
                    <th>Key</th>
                    <th>Value</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                { props.context && props.context.map((variable, index) => {
                    return (<tr className={css.Property} key={`__context_${variable.name}_${index}`}>
                        <td className={`${css.ImgCell}`}>
                            {defaultIcons[variable.type]}
                        </td>
                        <td className={`${css.Cell} ${css.TypeCell}`}>
                            {keyInput(index)}
                        </td>
                        { variable.type === 'boolean' &&
                            <td className={css.Cell}>
                                {booleanInput(index)}
                            </td>
                        }
                        { variable.type === 'string' &&
                            <td className={css.Cell}>
                                {textInput(index)}
                            </td>
                        }
                        { variable.type === 'image' &&
                            <td className={css.Cell}>
                                {readOnlyInput(index)}
                            </td>
                        }
                        { variable.type === 'embedding' &&
                            <td className={css.Cell}>
                                {embeddingInput(index)}
                            </td>
                        }
                        { variable.type === 'callback' &&
                            <td className={css.Cell}>
                                {scriptInput(index)}
                            </td>
                        }
                        { variable.type === 'number' &&
                            <td className={css.Cell}>
                                {textInput(index, true)}
                            </td>
                        }
                        { variable.type === 'function' &&
                            <td className={css.Cell}>
                                {scriptInput()}
                            </td>
                        }
                        <td className={`${css.Cell} ${css.CellActions}`}>
                            { variable.type === "string" &&
                            <FiEdit
                                className={css.Etc}
                                onClick={() => state.managerFunctions.openWin('ContextStringWin', {
                                    string: variable.value,
                                    setString: (string) => {
                                        const newContext = [...props.context]
                                        newContext[index].value = string
                                        props.setContext(newContext)
                                    }
                                })}
                            >
                                ...
                            </FiEdit>
                            }
                            { variable.type === "image" &&
                                <FiEdit
                                    className={css.Etc}
                                    onClick={() => state.managerFunctions.openWin('ContextImageWin', {
                                        url: variable.value,
                                        setUrl: (string, imageId) => {
                                            const newContext = [...props.context]
                                            newContext[index].value = string
                                            newContext[index].imageId = imageId || 0
                                            props.setContext(newContext)
                                        },
                                        imageId: variable.imageId || 0
                                    })}
                                >
                                    ...
                                </FiEdit>
                            }
                            { variable.type === "embedding" &&
                                <FiEdit
                                    className={css.Etc}
                                    onClick={() => state.managerFunctions.openWin('ContextEmbeddingWin', {
                                        promptId: props.promptId,
                                        embeddingId: variable.embeddingId || 0,
                                        setEmbedding: (embeddingId, splitter, sectionSize, sectionCount, minRate, algorithm) => {
                                            const newContext = [...props.context]
                                            newContext[index].embeddingId = embeddingId
                                            newContext[index].splitter = splitter
                                            newContext[index].sectionSize = sectionSize
                                            newContext[index].sectionCount = sectionCount
                                            newContext[index].minRate = minRate
                                            newContext[index].algorithm = algorithm
                                            props.setContext(newContext)
                                        },
                                        splitter: variable.splitter,
                                        sectionSize: variable.sectionSize,
                                        sectionCount: variable.sectionCount,
                                        status: variable.status,
                                        minRate: variable.minRate,
                                        algorithm: variable.algorithm,
                                    })}
                                >
                                    ...
                                </FiEdit>
                            }
                            { variable.type === "function" &&
                                <FiEdit
                                    className={css.Etc}
                                    onClick={() => state.managerFunctions.openWin('ContextScriptWin', {
                                        script: variable.value,
                                        setScript: (script) => {
                                            const newContext = [...props.context]
                                            newContext[index].value = script
                                            props.setContext(newContext)
                                        }
                                    })}
                                >
                                    ...
                                </FiEdit>
                            }
                            { variable.type === "callback" &&
                                <FiEdit
                                    className={css.Etc}
                                    onClick={() => state.managerFunctions.openWin('ContextScriptWin', {
                                        script: variable.value,
                                        setScript: (callback) => {
                                            const newContext = [...props.context]
                                            newContext[index].value = callback
                                            props.setContext(newContext)
                                        }
                                    })}
                                >
                                    ...
                                </FiEdit>
                            }
                            <AiFillDelete
                                className={css.DeleteIcon}
                                onClick={() => deleteKey(index)}
                            />
                        </td>
                    </tr>)
                })}
                </tbody>
            </table>
            <div
                className={css.Expand}
                onClick={() => setAddMenuVisibility(false)}
            >
            </div>
            <div className={css.Footer}>
                <Button
                    css={css.AddButton}
                    onClick={() => setAddMenuVisibility(visibility => !visibility)}
                >
                    <IoIosAdd
                        className={css.DeleteIcon}
                    />
                </Button>

                { addMenuVisibility && <div className={css.AddMenu}>
                    { ['String', 'Number', 'Boolean', 'Function', 'Embedding', 'Callback', 'Image'].map((kind, index) => {
                        return <div
                            key={`${kind}-${index}`}
                            className={css.AddMenuItem}
                            onClick={() => addVariable(kind.toLowerCase())}
                        >
                            {kind}
                        </div>
                    }) }
                </div> }
            </div>
        </div>
    )
}

export default ContextEditor
