import css from '../../UtilitaryWins.module.scss'
import localCss from './ContextImageWin.module.scss'
import Win from '../../../../../../library/win/Win'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Button from '../../../../../../library/button/Button'
import {Context} from '../../../../../../../data/Context'
import InputLabel from "../../../../../../library/input_label/InputLabel";
import TextInput from "../../../../../../library/textinput/TextInput";
import {createImage, fetchImage, updateImage} from "../../../../../../../services/api/Image";
import {ColorRing} from "react-loader-spinner";
import TextArea from "../../../../../../library/textarea/TextArea";

function ContextImageWin(props) {
    const {state} = useContext(Context);
    const [imageId, setImageId, refImageId] = useStateAndRef(0)
    const [url, setUrl] = useState('')
    const [status, setStatus, refStatus] = useStateAndRef(0)
    const [image, setImage] = useState({})
    const [description, setDescription] = useState('')
    const {dispatch} = useContext(Context)

    const statusLabel = {
        0: "changed",
        1: "processing",
        2: "processed"
    }

    const statusLabelColor = {
        0: localCss.Status0,
        1: localCss.Status1,
        2: localCss.Status2,
    }

    function saveImage() {
        //props.setUrl(url, imageId)

        if (imageId === 0 || imageId === undefined) {
            createImage(dispatch, { url }).then(response => {
                console.log(response)
                props.setUrl(url, response.data.ID)
                setImageId(response.data.ID)
                console.log('create')
            })
        } else {
            updateImage(dispatch, imageId, { url }).then(() => {
                props.setUrl(url, imageId)
                setStatus(1)
                setDescription('')
                console.log('update')
            })
        }
    }

    useEffect(() => {
        setImageId(props.imageId)
        setUrl(props.url)

        const intervalId = setInterval(monitor, 5000)

        return () => {
            clearInterval(intervalId)
        };
    }, [])

    useEffect(() => {
        if (!imageId && imageId === 0)
            return

        getImage()
    }, [imageId])

    useEffect(() => {
        if (Object.keys(image).length === 0)
            return

        setUrl(image.Url)
        setImageId(image.ID)
        setDescription(image.Description)
        console.log(image)
        console.log('EFFECT B', image.ID)
    }, [image])

    function monitor() {
        if (refStatus.current === 1)
            getImage()
    }

    function getImage() {
        if (!refImageId.current)
            return

        fetchImage(dispatch, refImageId.current).then(response => {
            if (response.data.Status !== 1) {
                setImage(response.data)
            }
            setStatus(response.data.Status)
            return response
        })
    }

    function useStateAndRef(initial) {
        const [value, setValue] = useState(initial);
        const valueRef = useRef(value);
        valueRef.current = value;
        return [value, setValue, valueRef];
    }

    return (
        <Win
            title="Image Editor"
            keyProp={props.keyProp}
            x={300}
            y={190}
            h={548}
            w={500}
        >
            <div className={css.Body}>
                <div className={css.ButtonBar}>
                    <div className={localCss.StatusContainer}>
                        <div className={`${localCss.StatusValue} ${statusLabelColor[status]}`}>
                            <span className={localCss.StatusLabel}>
                                status:
                            </span>
                            {statusLabel[status]}
                            {status === 1 && <ColorRing
                                visible={true}
                                height="14"
                                width="14"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />}
                        </div>
                    </div>
                    <InputLabel label="URL">
                        <TextInput
                            css={localCss.UrlInput}
                            rows={14}
                            text={url}
                            setText={setUrl}
                        />
                    </InputLabel>
                </div>

                <div className={localCss.ImageBlackArea}>
                    <img className={localCss.Image} src={url} alt="blackboard"/>
                </div>
                { description.length > 0 && <InputLabel label="Description" css={localCss.Description}>
                        <TextArea
                            rows={6}
                            text={description}
                            setText={setDescription}
                        />
                    </InputLabel>
                }
                <div className={css.ButtonBar}>
                    <Button
                        disabled={status === 1}
                        onClick={() => {
                            saveImage()
                        }}
                    >
                        Process
                    </Button>
                    <Button
                        onClick={() => state.managerFunctions.closeWin(state.activeWin)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Win>
    );
}

export default ContextImageWin;
