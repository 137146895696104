import baseApi from './BaseApi'

const fetchChains = (dispatch) => baseApi(dispatch).get("/chains")
const fetchChain = (dispatch, id) => baseApi(dispatch).get("/chains/" + id)
const createChain = (dispatch, data) => baseApi(dispatch).post("/chains", data)
const updateChain = (dispatch, id, data) => baseApi(dispatch).put("/chains/" + id, data)
const deleteChain = (dispatch, id) => baseApi(dispatch).delete("/chains/" + id)


export {
    fetchChains,
    fetchChain,
    createChain,
    updateChain,
    deleteChain,
}
