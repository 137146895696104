import css from './GSLWin.module.scss'
import Win from '../../../library/win/Win'
import React, {useContext, useEffect, useRef, useState} from "react";
import TextArea from "../../../library/textarea/TextArea";
import Button from "../../../library/button/Button";
import {Context} from "../../../../data/Context";

import Spinner from "../../../library/spinner/Spinner";
import {
    fetchPrompt,
    fetchPrompts,
} from "../../../../services/api/Prompt"
import ContextEditor from "../../../library/context_editor/ContextEditor";
import {interpolateContext} from "../../../../services/api/Context";

function GSLWin(props) {
    const [input, setInput] = useState('')
    const [prompt, setPrompt] = useState({})
    const {dispatch} = useContext(Context)
    const [history, setHistory] = useState([])
    const inputRef = useRef(null)
    const bottomRef = useRef(null)
    const [disabled, setDisabled] = useState(false)
    const [isWaiting, setIsWaiting] = useState(false)

    useEffect(() => {
        fetchPrompts(dispatch).then(response => {
            setPromptId(response.data.prompts[0].ID)
        })
    }, [])

    function pushMessage(role, content) {
        setHistory(history => [...history, {
            role, content
        }])
    }

    function appendLog(log, role = 'error') {
        //const timestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
        //setLogs(logs => logs + `[${timestamp}] ` + log + "\n")
        pushMessage(role, log)
    }

    function setPromptId(id) {
        setDisabled(true)

        fetchPrompt(dispatch, id).then(response => {
            setPrompt(response.data)
            setDisabled(false)
            inputRef.current.focus()
        })
    }

    function setContext(context) {
        setPrompt(prompt => {
            return {
                ...prompt,
                Context: {
                    ...prompt.Context,
                    Data: context,
                }
            }
        })
    }

    function indent(text) {
        return text
            .replaceAll('<', '&lt;')
            .replaceAll('>', '&gt;')
            .replaceAll(/\n/g, '<br/>')
    }

    useEffect(() => {
        setTimeout(() => {
            bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 200)

    }, [history])

    const roleCss = {
        'in': css.InRole,
        're': css.OutRole,
        'error': css.ErrorRole,
    }

    function ask() {
        if (input.length === 0) return

        inputRef.current.focus()

        setDisabled(true)
        setIsWaiting(true)

        pushMessage('in', input)

        interpolateContext(dispatch, prompt.ContextID, {
            input,
            context: prompt.Context.Data
        }).catch(error => {
            appendLog(error)
            setDisabled(false)
            setIsWaiting(false)
        }).then(response => {
            if (response.data.info && response.data.info.length > 0) {
                response.data.info.forEach(info => appendLog(info, 'js'))
            }

            if (response.data.error) {
                if (response.data.error.Message)
                    appendLog(`${response.data.error.Message} at function '${response.data.error.Location}'`)
                else
                    appendLog(`${response.data.error}`)
                setDisabled(false)
                setIsWaiting(false)
                return
            }

            pushMessage("re", response.data.result)

            setDisabled(false)
            setIsWaiting(false)
        })
    }

    return (
        <Win
            title="GSL Console"
            keyProp={props.keyProp}
            center={true}
            w={790}
            h={450}
        >
            <div className={css.Body}>
                <div className={css.TopBar}>
                    <div className={css.Buttons}>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                setHistory([])
                                inputRef.current.focus()
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                ask()
                            }}
                        >
                            Run
                        </Button>
                    </div>
                </div>
                    <div className={css.MainArea}>
                        <div className={css.SideBar}>
                            <ContextEditor
                                context={prompt.Context && prompt.Context.Data}
                                setContext={setContext}
                            />
                        </div>
                        <div className={css.ChatBox}>
                                <div className={css.LayoutContainer}>
                                    <div className={css.Loading}>
                                        { isWaiting &&
                                            <Spinner/>
                                        }
                                    </div>
                                    <div className={css.ChatHistory}>
                                        {history.map((message, index) => {
                                            return <div key={`history-${index}`} className={css.Message}>
                                                <div className={css.MessageContent}>
                                                    <div className={`${css.RoleName} ${roleCss[message.role]}`}>[{message.role}]</div>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: "&nbsp" + indent(message.content)}}
                                                    />
                                                </div>
                                            </div>
                                        })}
                                        <div ref={bottomRef}>
                                        </div>
                                    </div>
                                    <div className={css.InputArea}>
                                        <TextArea
                                            refer={inputRef}
                                            text={input}
                                            setText={setInput}
                                            onKeyDown={e => {
                                                if (!e.shiftKey && e.code === 'Enter' && !disabled) {
                                                    ask()
                                                    e.preventDefault && e.preventDefault();
                                                    return false;
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
            </div>
        </Win>
    );
}

export default GSLWin;
